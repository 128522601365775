<template>
  <a-menu
    :mode="mode"
    :selectable="false"
    class="star-menu"
    :openKeys.sync="openKeys"
  >
    <template v-for="el in listData">
      <a-sub-menu v-if="!!el.children" :key="el.name">
        <span slot="title">
          <a-menu-divider />
          <MydocMenuItem :item="el" />
        </span>
        <template v-for="item in el.children">
          <!-- menu分割线 -->
          <a-menu-divider v-if="item.topLine" :key="item.name + '_divider'" />
          <a-menu-item
            :disabled="item.disabled"
            :key="item.name"
            @click="handleClick(item)"
          >
            <MydocMenuItem :item="item" />
          </a-menu-item>
        </template>
      </a-sub-menu>

      <a-sub-menu v-else-if="el.name === 'tag'" :key="el.name">
        <span slot="title">
          <a-menu-divider />
          <MydocMenuItem :item="el" />
        </span>
        <a-menu-item @click="handleTag" class="menu-tag">
          <div class="menu-tag-add" @click="handleAddTag">
            <a-icon type="plus" />
            新增标签
          </div>
          <div class="menu-tag-input" v-if="isAdd">
            <a-input
              placeholder="请输入标签名称"
              v-model="addTagValue"
              @pressEnter="addTagOk"
              :maxLength="10"
            ></a-input>
          </div>
          <div
            class="menu-tag-item"
            v-for="tag in tagList"
            :key="tag.id"
            @click="fileAddTag(tag.id)"
          >
            <span>{{ tag.tagName }}</span>
            <h-icon
              v-if="tag.checked"
              className="tag-icon"
              iconName="check"
            ></h-icon>
          </div>
        </a-menu-item>
      </a-sub-menu>

      <a-sub-menu v-else-if="el.name == 'knowledgeBase'" :key="el.name">
        <span slot="title">
          <a-menu-divider />
          <MydocMenuItem :item="el" />
        </span>
        <a-menu-item @click="handleKnowledge" class="menu-tag">
          <div class="menu-tag-add" @click="handleAddFileToKnowledge">
            <a-icon type="plus" />
            新增知识库
          </div>
          <div class="menu-tag-input" v-if="isAddOfKnowledge">
            <a-input
              placeholder="请输入知识库名称"
              v-model="addknowledeValue"
              @pressEnter="addknowledgeOk"
              :maxLength="10"
            ></a-input>
          </div>
          <div
            class="menu-tag-item"
            v-for="item in knowledgeBaseList_clone"
            :key="item.knowledgeId"
            @click="fileAddToKnowledeBase(item.knowledgeId)"
          >
            <span>{{ item.knowledgeName }}</span>
            <h-icon
              v-if="item.checked"
              className="tag-icon"
              iconName="check"
            ></h-icon>
          </div>
        </a-menu-item>
      </a-sub-menu>

      <template v-else>
        <!-- menu分割线 -->
        <a-menu-divider v-if="el.topLine" :key="el.name + '_divider'" />

        <a-menu-item :key="el.name" @click="handleClick(el)">
          <MydocMenuItem
            :item="
              el.name === 'favorite'
                ? {
                    ...el,
                    text:
                      vm.checkFile && vm.checkFile.isCollected
                        ? '取消收藏'
                        : '收藏',
                  }
                : el
            "
          />
        </a-menu-item>
      </template>
    </template>
  </a-menu>
</template>

<script>
import Vue from "vue";
import MydocMenuItem from "./menuItem.vue";
import menuJson from "./json/menuData";
import { docToolMenuList } from "@/utils/index";
// console.log('docToolMenuList',docToolMenuList);
export default Vue.extend({
  name: "mydocMenu",
  components: {
    MydocMenuItem,
  },
  props: {
    checkedFileItemList: {
      type: Array,
      default: [],
    },
    mode: {
      type: String,
      default: "vertical",
    },
    type: {
      type: String,
      default: "normal",
      validator(value) {
        return Object.keys(menuJson).includes(value);
      },
    },
    moduleType: {
      type: String,
      default: "doc",
    },
  },
  // 父组件实例 需要获取选中文件判断显示收藏还是取消收藏
  inject: ["vm"],
  data() {
    return {
      isAddOfKnowledge: false,
      addknowledeValue: "",
      openKeys: [],
      // 所有标签
      tagList: [],
      // 选中标签
      checkedTagList: [],
      // 新增标签
      addTagValue: "",
      // 新增标签
      isAdd: false,

      // teamList: [],
    };
  },
  watch: {
    fileTagList: {
      handler(newVal) {
        this.tagList = JSON.parse(JSON.stringify(newVal));
        this.setTagListChecked();
      },
      deep: true,
      immediate: true,
    },
    knowledgeBaseList: {
      handler(newVal) {
        this.knowledgeBaseList_clone = JSON.parse(JSON.stringify(newVal));

        console.log(
          "this.checkedFileItemList[0].knowledgeIds",
          this.checkedFileItemList[0].knowledgeIds
        );
        console.log(
          "  this.knowledgeBaseList_clone",
          this.knowledgeBaseList_clone
        );

        this.setKnowledgeListChecked();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // 是否开启了审核
    isAudit: function () {
      return this.$store.state.user.isAudit;
    },

    personalMailboxEnterprise() {
      return this.$store.state.user.personalMailboxEnterprise;
    },
    // 团队列表
    teamList: function () {
      return this.$store.state.team.teamList;
    },
    // 所有知识库列表
    knowledgeBaseList() {
      return this.$store.state.knowledgeBase.knowledgeBaseList;
    },
    listData: function () {
      //@ts-ignore

      let menuList = JSON.parse(JSON.stringify(menuJson[this.type]));
      console.log("🚀 ~ file: menu.vue:120 ~ this.type:", this.type);
      if (this.type === "normal" || this.type === "full") {
        let index = menuList.findIndex((i) => i.name === "sendTeam");

        if (this.teamList.length === 0) {
          menuList.splice(index, 1);
        } else {
          menuList[index].children = this.teamList.map((i) => {
            return {
              ...i,
              text: i.teamName,
              icon: "",
              name: i.id,
              func: "sendTeamAction",
              functionCode: "COPY_FILE",
            };
          });
        }
      }

      if (this.personalMailboxEnterprise) {
        let index = menuList.findIndex((item) => item.name === "sendTeam");
        if (index !== -1) {
          menuList.splice(index, 1);
        }
      }

      if (this.type === "full" || this.type === "teamFull") {
        console.log(
          "单个菜单--this.checkedFileItemList-",
          this.checkedFileItemList
        );

        // this.isAudit == 1 代表开启了审核
        if (
          this.isAudit == 1 &&
          this.checkedFileItemList[0]?.auditStatus &&
          this.checkedFileItemList[0]?.auditStatus != 3
        ) {
          menuList = [
            {
              text: "移到回收站",
              icon: "",
              name: "delete",
              func: "delete",
              topLine: true,
              functionCode: "DELETE_FILE",
              fontStyle: {
                color: "#F52F3E",
              },
            },
          ];
        } else {
          // 追加文档工具菜单  要求单选文档并且不能是文件夹, 并且需要文档类型才出现这个菜单
          let targetFileType = [
            "doc",
            "docx",
            "pdf",
            "ppt",
            "pptx",
            "xls",
            "xlsx",
          ];
          if (
            this.checkedFileItemList.length &&
            this.checkedFileItemList[0].userFileType !== "folder" &&
            targetFileType.includes(
              this.checkedFileItemList[0].extensionName.toLowerCase()
            )
          ) {
            let fileExtension =
              this.checkedFileItemList[0].extensionName.toLowerCase();
            // 禁用逻辑
            console.log("选中的文件扩展名是什么", fileExtension);
            docToolMenuList.forEach((item) => {
              switch (fileExtension) {
                case "pdf":
                  // PDF文件仅支持PDF转word及PDF编辑四项
                  if (item.name !== "PDF_Word" && item.type !== "pdfEdit") {
                    item.disabled = true;
                  } else {
                    item.disabled = false;
                  }
                  break;
                case "doc":
                case "docx":
                  // Word文件仅支持word转PDF
                  if (item.name !== "Word_PDF") {
                    item.disabled = true;
                  } else {
                    item.disabled = false;
                  }
                  break;
                case "xls":
                case "xlsx":
                  // Excel文件仅支持Excel转PDF
                  if (item.name !== "Excel_PDF") {
                    item.disabled = true;
                  } else {
                    item.disabled = false;
                  }
                  break;
                case "ppt":
                case "pptx":
                  // PPT文件仅支持PPT转PDF
                  if (item.name !== "PPT_PDF") {
                    item.disabled = true;
                  } else {
                    item.disabled = false;
                  }
                  break;
                default:
                  // 不支持的文件类型，全部禁用
                  item.disabled = true;
                  break;
              }
            });

            // menuList.push(docToolMenuList);
            let docMenuInfo = {
              text: "文档工具",
              icon: "",
              name: "documentTool",
              func: "documentTool",
              subMenu: true,
              children: docToolMenuList,
            };
            // 查找 "收藏" 项的索引
            let favoriteIndex = menuList.findIndex(
              (item) => item.name === "favorite"
            );
            // 如果找到了 "收藏" 项
            if (favoriteIndex !== -1) {
              // 在 "收藏" 项前面插入 docMenuInfo
              menuList.splice(favoriteIndex, 0, docMenuInfo);
            } else {
              // 如果没有找到 "收藏" 项，则可以将 docMenuInfo 追加到 menuList 的末尾或其他合适的位置
              menuList.push(docMenuInfo);
            }
          }

          // 追加加入知识库菜单 文档类型匹配才出现这个菜单
          let targetKnowledeFileType = ["doc", "docx", "pdf", "txt"];
          if (
            this.checkedFileItemList.length &&
            this.checkedFileItemList[0].userFileType !== "folder" &&
            targetKnowledeFileType.includes(
              this.checkedFileItemList[0].extensionName.toLowerCase()
            )
          ) {
            let tempInfo_ =
              this.knowledgeBaseList?.length &&
              this.knowledgeBaseList.map((i) => {
                return {
                  ...i,
                  text: i.knowledgeName,
                  icon: "",
                  name: i.knowledgeId,
                  func: "addToKnowledgeBase",
                };
              });

            let knowledgeBaseInfo = {
              text: "添加到知识库",
              icon: "",
              name: "knowledgeBase",
              func: "knowledgeBase",
              subMenu: true,
              // children: tempInfo_,
            };
            // 查找 "收藏" 项的索引
            let favoriteIndex = menuList.findIndex(
              (item) => item.name === "favorite"
            );
            // 如果找到了 "收藏" 项
            if (favoriteIndex !== -1) {
              // 在 "收藏" 项前面插入 docMenuInfo
              menuList.splice(favoriteIndex, 0, knowledgeBaseInfo);
            } else {
              // 如果没有找到 "收藏" 项，则可以将 docMenuInfo 追加到 menuList 的末尾或其他合适的位置
              menuList.push(knowledgeBaseInfo);
            }

            this.setKnowledgeListChecked();
          }
        }
      } else if (this.type === "normal" || this.type === "teamNormal") {
        if (
          this.isAudit === 1 &&
          this.checkedFileItemList.some(
            (item) => item?.auditStatus && item?.auditStatus !== 3
          )
        ) {
          menuList = [
            {
              text: "移到回收站",
              icon: "",
              name: "delete",
              func: "delete",
              topLine: true,
              functionCode: "DELETE_FILE",
              fontStyle: {
                color: "#F52F3E",
              },
            },
          ];
        }
      }

      // TODO 这里有空了封装一下函数调用，复用方法
      if (this.type === "create") {
        console.log("this.$router.query", this.$route);

        //@ts-ignore
        if (this.$route.query?.fileType) {
          // 开启了审核就不支持上传文件夹
          if (this.isAudit == 1) {
            menuList = [
              {
                text: "上传文件",
                iconfont: "shangchuanwenjian",
                name: "uploadFile",
                func: "uploadFile",
                iconStyle: {
                  fontSize: "18px",
                  marginRight: "10px",
                },
                topLine: true,
                functionCode: "UPLOAD_LOCAL_FILE",
              },
            ];
          } else {
            menuList = [
              {
                text: "上传文件",
                iconfont: "shangchuanwenjian",
                name: "uploadFile",
                func: "uploadFile",
                iconStyle: {
                  fontSize: "18px",
                  marginRight: "10px",
                },
                topLine: true,
                functionCode: "UPLOAD_LOCAL_FILE",
              },
              {
                text: "上传文件夹",
                iconfont: "shangchuanwenjianjia",
                name: "uploadFolder",
                func: "uploadFolder",
                iconStyle: {
                  fontSize: "18px",
                  marginRight: "10px",
                },
                functionCode: "UPLOAD_LOCAL_DIR",
              },
            ];
          }
        } else {
          // 开启了审核就不支持上传文件夹
          if (this.isAudit == 1) {
            menuList = [
              {
                text: "新建文件夹",
                hicon: "folder",
                name: "createFolder",
                func: "createFolder",
                iconStyle: {
                  width: "18px",
                  marginRight: "10px",
                },
                functionCode: "CREATE_FILE",
              },
              {
                text: "上传文件",
                iconfont: "shangchuanwenjian",
                name: "uploadFile",
                func: "uploadFile",
                iconStyle: {
                  fontSize: "18px",
                  marginRight: "10px",
                },
                topLine: true,
                functionCode: "UPLOAD_LOCAL_FILE",
              },
            ];
          } else {
            menuList = [
              {
                text: "新建文件夹",
                hicon: "folder",
                name: "createFolder",
                func: "createFolder",
                iconStyle: {
                  width: "18px",
                  marginRight: "10px",
                },
                functionCode: "CREATE_FILE",
              },
              {
                text: "上传文件",
                iconfont: "shangchuanwenjian",
                name: "uploadFile",
                func: "uploadFile",
                iconStyle: {
                  fontSize: "18px",
                  marginRight: "10px",
                },
                topLine: true,
                functionCode: "UPLOAD_LOCAL_FILE",
              },
              {
                text: "上传文件夹",
                iconfont: "shangchuanwenjianjia",
                name: "uploadFolder",
                func: "uploadFolder",
                iconStyle: {
                  fontSize: "18px",
                  marginRight: "10px",
                },
                functionCode: "UPLOAD_LOCAL_DIR",
              },
            ];
          }
        }
      }

      let result = menuList.filter((item) => {
        if (item.hasOwnProperty("functionCode")) {
          // (this.$store as any).state.user.functionCodes.includes(
          // if ( functionCodes.includes(item.functionCode )) {
          //   return item;
          // }
          console.log("0003", item);

          return item;
        } else if (item.authority) {
          // console.log(this.$store.state.team.currentTeamAuthority)
          /* 团队文件的权限校验暂时跳过,由后台处理 */
          console.log("0002", item);
          return item;
        } else {
          console.log("0001", item);
          return item;
        }
      });

      console.log("菜单result", result);
      //@ts-ignore
      return result;

      // }
    },
    // 所有标签
    fileTagList() {
      return this.$store.state.menu.tagList;
    },
  },
  methods: {
    handleKnowledge() {
      this.$nextTick(() => {
        console.log("保持会话");
        this.openKeys = ["knowledgeBase"];
      });
    },

    // 新增知识库
    handleAddFileToKnowledge() {
      this.addknowledeValue = "";
      this.isAddOfKnowledge = true;
    },

    // 确认新增知识库
    async addknowledgeOk() {
      console.log("新增知识库--val", this.addknowledeValue);
      const params = {
        knowledgeName: this.addknowledeValue,
      };
      try {
        const res = await this.$apis.addKnowledgeHttp(params);
        this.$message.success("新增成功");
        this.$store.dispatch("knowledgeBase/async_setKnowledgeBaseList_ByHttp");
        // store.dispatch("knowledgeBase/async_setKnowledgeBaseList_ByHttp");
        this.$nextTick(() => {
          console.log("保持会话");
          this.openKeys = ["knowledgeBase"];
        });
      } catch (error) {
        console.log("新增知识库error", error);
      }
      this.addknowledeValue = "";
      this.isAddOfKnowledge = false;
    },

    // 新增文件到知识库
    async fileAddToKnowledeBase(id) {
      console.log("知识库id", id);

      this.knowledgeBaseList_clone.forEach((i) => {
        if (i.knowledgeId == id) {
          if (!i.checked) {
            this.addFileToKnowledeBaseFn(id);
          } else {
            this.removeFilefromKnowledeBaseFn(id);
          }
        }
      });
    },

    // 给文件加入对应知识库--addFileToKnowledeBaseFn
    async addFileToKnowledeBaseFn(id) {
      const params = {
        knowledgeIds: [id],
        userFileIds: [this.checkedFileItemList[0].id],
      };
      console.log("单文件菜单--添加文件到知识库的参数", params);
      try {
        const res = await this.$apis.addFileToKnowledgeHttp(params);
        console.log("单文件菜单--添加文件到知识库", res);
        // 好像就云文档的后端返回状态码code是字符串，其他本来就是数字
        this.$message.success("添加成功");

        this.knowledgeBaseList_clone.forEach((i) => {
          if (i.knowledgeId == id) {
            this.$set(i, "checked", true);
          }
        });
        // setTimeout(() => {
        //   this.getKnowledgeFileList();
        // }, 10);
      } catch (error) {
        console.log("添加文件到知识库--error", error);
      } finally {
        this.openKeys = ["knowledgeBase"];
      }
    },

    // 从知识库移除文件--removeFilefromKnowledeBaseFn
    async removeFilefromKnowledeBaseFn(id) {
      const params = {
        knowledgeId: id,
        userFileId: this.checkedFileItemList[0].id,
      };
      console.log("单文件菜单--从知识库移除文件的参数", params);
      try {
        const res = await this.$apis.removeKnowledgeFileHttp(params);
        console.log("单文件菜单--从知识库移除文件", res);
        // 好像就云文档的后端返回状态码code是字符串，其他本来就是数字
        this.$message.success("移除成功");

        this.knowledgeBaseList_clone.forEach((i) => {
          if (i.knowledgeId == id) {
            this.$set(i, "checked", false);
          }
        });
        // setTimeout(() => {
        //   this.getKnowledgeFileList();
        // }, 10);
      } catch (error) {
        console.log("知识库移出文件--error", error);
      } finally {
        this.openKeys = ["knowledgeBase"];
      }
    },

    queryTeamList() {
      // 先掉一个默认团队的接口
      let companyId = this.$store.state.user.companyId;
      let companyList = this.$store.state.user.companyList;
      const company = companyList.find((obj) => obj.id == companyId);
      // this.$apis
      //   .companyTeamDefaultPageList({
      //     companyId: this.$store.state.user.companyId,
      //     companyName: company.name
      //   })
      //   .then((res: any) => {
      //     if (res.code === "200") {
      this.$apis
        .companyTeamPageList({
          companyId: this.$store.state.user.companyId,
          isPage: 0,
        })
        .then((res) => {
          if (res.code === "200") {
            let teamList = res.data.list;
            teamList.sort((a, b) =>
              a.isStaff === 1 ? -1 : b.isStaff === 1 ? 1 : 0
            );
            this.$store.commit("team/SET_TEAMLIST", teamList);
            // console.log('企业团队res', this.teamList);
          }
        });

      // }
      // });
    },

    // 查询当前文件选中标签
    queryCheckedTag(checkFileList) {
      if (checkFileList.length === 1) {
        console.log(checkFileList);
        this.$apis
          .documentTagQueryFileTag({
            id: checkFileList[0],
            moduleType: this.moduleType,
            companyId:
              this.moduleType === "doc"
                ? "person"
                : this.$store.state.user.companyId,
          })
          .then((res) => {
            if (res.code === "200") {
              this.checkedTagList = res.data.map((i) => i.id);
              this.setTagListChecked();
            }
          });
      } else {
        this.checkedTagList = [];
        this.setTagListChecked();
      }
    },
    setTagListChecked() {
      this.tagList.forEach((i) => {
        if (this.checkedTagList.includes(i.id)) {
          this.$set(i, "checked", true);
        } else {
          this.$set(i, "checked", false);
        }
      });
    },

    setKnowledgeListChecked() {
      this.knowledgeBaseList_clone.forEach((i) => {
        if (
          this.checkedFileItemList[0]?.knowledgeIds?.length &&
          this.checkedFileItemList[0].knowledgeIds.includes(i.knowledgeId)
        ) {
          this.$set(i, "checked", true);
        } else {
          this.$set(i, "checked", false);
        }
      });
    },

    handleClick(el) {
      if (el.name === "favorite") {
        let value = {
          ...el,
          text:
            this.vm.checkFile && this.vm.checkFile.isCollected
              ? "取消收藏"
              : "收藏",
        };
        this.$emit("handleClick", value);
      } else {
        this.$emit("handleClick", el);
      }
    },
    handleTag(e) {
      if (
        this.$route.path.includes("tag") &&
        this.$route.query.tagName === e.domEvent.target.innerText
      ) {
        return;
      }
      this.$nextTick(() => {
        console.log("保持会话");
        this.openKeys = ["tag"];
      });
    },
    handleAddTag() {
      this.addTagValue = "";
      this.isAdd = true;
    },
    addTagOk() {
      if (this.addTagValue === "") {
        this.isAdd = false;
        return;
      } else {
        this.$apis
          .documentTagAddTag({
            tagName: this.addTagValue,
            companyId: this.$store.state.user.companyId,
          })
          .then((res) => {
            if (res.code === "200") {
              // this.$message.success("新增标签成功");
              this.$store.dispatch("menu/GET_TAGLIST");
              this.$emit("handleClick", {
                func: "addTag",
                value: res.data,
              });
            }
          })
          .finally(() => {
            this.isAdd = false;
          });
      }
    },
    fileAddTag(tagId) {
      this.$emit("handleClick", {
        func: "addTag",
        value: tagId,
      });
    },
  },
  created() {
    console.log("this.listData", this.listData);
    if (this.type === "normal" || this.type === "full") this.queryTeamList();
  },
});
</script>

<style scoped lang="less">
.star-menu {
  &:extend(.xy-shadow1);
  text-align: left;
  background: #fff;
  border-radius: 2px;
  // antd加了竖向menu删除boxShadow, 权重高于extend继承
  box-shadow: 0px 2px 8px 0px rgba(26, 62, 153, 0.1);

  /deep/ .ant-menu-submenu,
  .ant-menu-item {
    height: 34px;
    line-height: 34px;
    margin: 0;

    .ant-menu-submenu-title {
      height: 34px;
      line-height: 34px;
    }
  }
}

.menu-tag {
  height: auto !important;
  padding: 0;
  cursor: default;

  .menu-tag-add {
    height: 34px;
    line-height: 34px;
    font-size: 14px;
    color: #4a61ff;
    padding-left: 15px;
    border-bottom: 1px solid #f2f2f2;
    cursor: pointer;
  }

  .menu-tag-input {
    background: #ffffff;
    padding: 0 16px;

    input {
      width: 124px;
      height: 26px;
      border-radius: 4px;
    }
  }

  .menu-tag-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    cursor: pointer;

    span {
      display: block;
      width: 120px;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .tag-icon {
      width: 12px !important;
      height: 8px !important;
    }
  }

  .menu-tag-item:hover {
    background-color: #f6f6f8;
  }
}

.menu-tag:hover {
  color: rgba(0, 0, 0, 0.65);
}

.ant-menu-vertical .ant-menu-item {
  height: 34px;
  line-height: 34px;
  margin: 0;
}
</style>


import Vue from "vue";
import { Component, Prop, Watch, Ref, Inject } from "vue-property-decorator";
import FileTag from "./FileTag.vue";
import { imgEx } from "@/common/utils/fileEx";
import { namespace } from "vuex-class";

const UserModule = namespace("user");

@Component({
  name: "FileDetails",
  components: { FileTag },
  filters: {
    //局部过滤器
  },
})
export default class FileDetails extends Vue {
  @UserModule.State("token") token!: string;
  @UserModule.State("companyId") companyId!: string;

  public fileData: any = {};
  public empty: boolean = false;
  public imgUrl: string = "";
  public isImg: boolean = false;
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop()
  readonly file!: any;
 @Prop({
    type: String,
    default: "doc",
  })
  root!: any;
  @Inject() readonly vm!: any;

  @Prop({
    type: String,
    default: "doc",
  })
  moduleType!: string;
  //计算属性
  //get computedMsg(): string {
  //  return ''
  //}

  // 关闭详情显示
  handleClose(): void {
    this.$emit("sendClose");
  }
  // 添加/删除文件标签
  handleTag(tagId: string): void {
    this.$apis
      .documentTagUpdateFileTag({
        moduleType: this.moduleType,
        companyId: this.moduleType === 'doc' ? 'person' : this.companyId,
        objId: this.file[this.vm.id],
        tagId: tagId,
      })
      .then((res: any) => {
        if (res.code === "200") {
          this.$message.success("操作成功");
          this.queryFileDetail();
        }
      });
  }
  queryFileDetail(): void {
    if (this.file) {
      const apisName =
        this.moduleType === "team" ? "companyFileDetail" : "userFileDetail";
      this.$apis[apisName](this.file[this.vm.id]).then((res: any) => {
        if (res.code === "200") {
          this.fileData = res.data;
          console.log('-文件所在位置详情--fileData-----',this.fileData);
          let temp=(this.root.textParent?this.root.textParent:this.root.text)+this.fileData.userFilePath.slice(0,-1) 
          console.log('-----this.root----',this.root);
          console.log('-----this.root.textParent-----',this.root.textParent);
          
          
          this.empty = false;
          this.$nextTick(() => {
            this.FileTagRef.setTagListChecked();
          });
        }
      });
    } else {
      this.empty = true;
    }
  }

  // 获取图片
  queryImgUrl(): void {
    this.imgUrl = "";
    if (imgEx.includes(this?.file?.extensionName)) {
      this.isImg = true;
      this.$apis
        .userFilePreview({
          token: this.token,
          userFileId: this.file[this.vm.id],
          channelType: "web",
        })
        .then((res: any) => {
          if (res) this.imgUrl = window.URL.createObjectURL(res);
        });
    } else {
      this.isImg = false;
    }
  }

  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  @Watch("file", { deep: true, immediate: true })
  fileChange() {
    this.queryImgUrl();
    this.queryFileDetail();
  }
  //ref 引用元素 Ref('ref')
  @Ref("FileTag")
  FileTagRef!: any;
  //创建前钩子函数
  created(): void {
  }
}

<template>
  <transition name="fade">
    <div class="loading" v-if="visible">
      <a-spin size="large" tip="全力加载中..." />
    </div>
  </transition>
</template>
<script>
export default {
  name: 'loading',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="less" scoped>
.loading {
	z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

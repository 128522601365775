
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import setMap from "./json/setMap";
import { namespace } from "vuex-class";

const UserModule = namespace("user");
// 可选type列表
let typeList = Object.keys(setMap),
  // 配置字段
  fieldList = Object.keys(setMap.createFile);
@Component({
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class ClassComponent extends Vue {
  @UserModule.State("companyId") companyId!: string;

  title: string = "";
  okText: string = "";
  placeholder: string = "";
  formModel: any = {
    value: "",
  };
  layout: { labelCol: any; wrapperCol: any } = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };
  rules: any = {
    value: [
      {
        validator: (rule: any, value: any, callback: any) => {
          if (!value) {
            callback(new Error("内容不能为空"));
          } else {
            callback();
          }
        },
        trigger: "change",
      },
      { validator: this.checkNonEmptyString, trigger: 'change' }
    ],
  };
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({
    type: String,
    default: "createFile",
    validator(value: string) {
      return typeList.includes(value);
    },
  })
  type!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  visible!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  })
  loading!: boolean;

  @Prop({
    type: Object,
    default: () => { },
  })
  options!: any;

  checkNonEmptyString(rule: any, value: string, callback: (arg0: Error | undefined) => void) {
    if (!value || value.trim() === '') {
      callback(new Error('不能输入空字符串'));
    } else {
      // @ts-ignore
      callback(); // 在这里调用 callback，表示验证成功  
    }
  }

  onClickOk() {
    // 发起所有表单校验

    // alert(689999);
      (this.$refs.formModel as any).validate((valid: any) => {
        if (valid) {
          this.$emit("loading", true);
          // 校验通过
          let publicData: any = {
            userFileName: this.formModel.value,
            parentId: this.$store.state.share.pathId,
          }, //  接口公用字段
            data: any = {}, // 接口实际字段
            api = "", // api名称
            msg = "", // 成功后的提示
            refresh = true, // 关闭之后是否需要刷新, 默认刷新
            moduleType: string = this.$route.path.includes("companyTeam/document")
              ? "team"
              : "doc", // 模块类型:doc-个人文档,team-企业团队
            teamData: any = {}; //企业模块额外接口字段
          if (moduleType === "team") {
            teamData = {
              companyId: this.companyId,
              teamId: this.$route.query.teamId,
              moduleType,
            };
          } else {
            teamData = {
              moduleType,
            };
          }
          if (this.type === "createFile") {
            // 新建文件
            data = {
              parentId: publicData.parentId,
              extensionName: this.options.suffix,
              fileName: this.formModel.value,
              companyId: moduleType === 'doc' ? 'person' : this.companyId
            };
            api = "userFileAdd";
            msg = "新增文件成功";
          } else if (this.type === "createFolder") {
            // 新建文件夹
            data = Object.assign({}, publicData);
            if (moduleType === "team") {
              api = "companyFileAddFolder";
              teamData = { teamId: this.$route.query.teamId };
            } else {
              api = "userFileAddFolder";
              teamData = {};
            }
            msg = "新增文件夹成功";
          } else if (this.type === "rename") {
            // 重命名
            data = {
              id: this.options.item.id,
              userFileName: publicData.userFileName,
            };
            teamData = {
              teamId: this.$route.query.teamId,
            };
            api = moduleType === "team" ? "companyFileRename" : "userFileRename";
            msg = "重命名成功";
          } else if (this.type === "remark") {
            // 添加备注
            data = {
              id: this.options.item.id,
              remark: this.formModel.value,
            };
            teamData = {
              teamId: this.$route.query.teamId,
            };
            api =
              moduleType === "team"
                ? "companyFileUpdateRemark"
                : "userFileUpdateRemark";
            msg = "修改文件备注成功";
            refresh = true;
          }
          if (api) {
            // 接口调用
            this.$apis[api]({ ...data, ...teamData })
              .then((res: any) => {
                var fileId;
                this.$message.success(msg);
                if (api === "userFileAdd") {
                  setTimeout(() => {
                    let query = {
                      userFileId: res,
                      moduleType: moduleType,
                      fileExtension: data.extensionName,
                      fileName: data.fileName,
                      ot: "edit",
                    };
                    let routeData = this.$router.resolve({
                      name: "VIEW_OFFICE_ONLINE",
                      query,
                    });
                    window.open(routeData.href, "_blank"); // 新窗口打开
                  }, 300);
                  fileId = res;
                } else if (
                  api === "companyFileAddFolder" ||
                  api === "userFileAddFolder"
                ) {
                  fileId = res.data;
                }
                this.$emit("close", refresh, fileId);
              })
              .finally((e: any) => {
                this.$emit("loading", false);
              });
          } else {
            this.$emit("loading", false);
          }
        }
      });
  }

  onClickCancle() {
    this.$emit("close");
  }

  @Watch("type", { immediate: true })
  typeChange(el: string) {
    //@ts-ignore
    if (el && setMap[el]) {
      fieldList.forEach((key: string) => {
        // 页面除表单之外元素初始化
        //@ts-ignore
        if (this[key] !== undefined) this[key] = setMap[el][key] || "";
      });
    }
  }

  created() {
    // 页面表单元素初始化赋值
    if (this.type === "remark") {
      this.formModel.value = this.options.item.remark;
    } else if (this.type === "rename") {
      this.formModel.value = this.options.item.userFileName;
    } else {
      this.formModel.value = "";
    }
  }
}

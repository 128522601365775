const actions = [
    {
        label: '仅可查看',
        value: 1,
    },
    {
        label: '可查看/下载',
        value: 2,
    },
    {
        label: '可查看/下载/上传',
        value: 3,
    },
    {
        label: '可查看/下载/上传/删除',
        value: 4,
    },
]

// 用户列
const userColumns = [
    {
        width: '150px',
        dataIndex: 'name',
        key: 'name',
    },
    {
        dataIndex: 'roleType',
        key: 'roleType',
        scopedSlots: { customRender: 'roleType' },
    },
]


const previewTabTitle = [
    {
      dataIndex: 'fileName',
      key: 'fileName',
      title: '名称',
      scopedSlots: { customRender: 'fileName' },
    },
    {
      title: '最近访问',
      dataIndex: 'viewTime',
      key: 'viewTime',
      width: 200,
      fixed: 'right',
    },
    {
      title: '大小',
      key: 'fileSize',
      dataIndex: 'fileSize',
      width: 150,
      fixed: 'right',
    },
    {
      title: '操作',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      width: 50,
      fixed: 'right',
    },
  ]
  const TabTitle = [
    {
      dataIndex: 'docName',
      key: 'docName',
      title: '文档名称',
      // slots: { title: 'customTitle' },
      scopedSlots: { customRender: 'docName' },
    },
    {
      title: '大小',
      dataIndex: 'fileSize',
      key: 'fileSize',
      width: 150,
      fixed: 'right',
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 200,
      fixed: 'right',
    },
    {
      title: '创建者',
      dataIndex: 'createBy',
      key: 'createBy',
      ellipsis: true,
      width: 150,
      fixed: 'right',
    },
    {
      title: '操作',
      key: 'action',
      scopedSlots: { customRender: 'action' },
      width: 50,
      fixed: 'right',
    },
  ]

// 支持预览的文件
const preivewSuffixs = [
    'txt',
    'doc',
    'docx',
    'pdf',
    'jpg',
    'png',
    'jpeg',
    'xls',
    'xlsx',
    'csv',
    'TXT',
    'DOC',
    'DOCX',
    'PDF',
    'JPG',
    'PNG',
    'JPEG',
    'XLS',
    'XLSX',
    'CSV',
]

// 顶部操作方法
const topEditArr = [
  { text: '上传', method: 'uploadFileModal' },
  { text: '新建文件夹', method: 'createFolder' },
  { text: '批量下载', method: 'BulkdownLoads' }
]
const topEditArrNoDownload = [
  { text: '上传', method: 'uploadFileModal' },
  { text: '新建文件夹', method: 'createFolder' }
]

// router
const ROUTER_BASE = process.env.VUE_APP_ROUTER_BASE //'library-web' // smartLibrary
const ROUTER_PROJECT = 'project'

export {
  actions,
  userColumns,
  TabTitle,
  previewTabTitle,
  preivewSuffixs,
  topEditArr,
  topEditArrNoDownload,
  ROUTER_BASE,
  ROUTER_PROJECT,
}

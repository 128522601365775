
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import setMap from "./json/setMap";
import { log } from "console";
// 可选type列表
let typeList = Object.keys(setMap),
  // 配置字段
  fieldList = Object.keys(setMap.delete);
@Component({
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class ClassComponent extends Vue {
  loading: Boolean = false;
  title: string = "";
  msg: string = "";
  msg2: string = "";
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({
    type: String,
    default: "delete",
    validator(value: string) {
      return typeList.includes(value);
    },
  })
  type!: string;

  @Prop({
    type: Boolean,
    default: false,
  })
  visible!: boolean;

  @Prop({
    type: Object,
    default: () => { },
  })
  options!: any;

  @Prop({
    type: String,
    default: "doc",
  })
  moduleType!: string;

  onClickOk() {
    if (this.type === "delete") {
      this.loading = true
      if (this.moduleType === "team") {
        // 团队文件校验权限
        this.$apis
          .companyFileCheck({
            sourceIds: this.options.checkIdList || [],
            teamId: this.options.item.teamId || this.$route.query.teamId,
          })
          .then((res: any) => {
            if (res.code === "200") {
              this.loading = false
              // data为无权限文件名的数组
              if (!res.data || res.data.length === 0) {
                this.$apis
                  .companyFileDelete({
                    sourceIds: this.options.checkIdList || [],
                    teamId:
                      this.options.item.teamId || this.$route.query.teamId,
                  })
                  .then((res: any) => {
                    this.$message.success("删除成功");
                    this.$emit("close", true);
                  });
              } else {
                const that = this;
                const h = this.$createElement;
                this.$confirm({
                  title: "文件删除",
                  content: h("div", {}, [
                    h(
                      "p",
                      "以下文件无操作权限,是否继续执行删除(无操作权限无法删除)"
                    ),
                    h("p", `无操作权限文件：${res.data.join("、")}`),
                  ]),
                  okText: "继续删除",
                  cancelText: "取消",
                  onOk() {
                    that.$apis
                      .companyFileDelete({
                        sourceIds: that.options.checkIdList || [],
                        teamId:
                          that.options.item.teamId || that.$route.query.teamId,
                      })
                      .then((res: any) => {
                        that.$message.success("删除成功");
                        that.$emit("close", true);
                      });
                  },
                  onCancel() {
                    console.log("Cancel");
                  },
                });
              }
            }
          }).catch((err: any) => {
            console.log('err', err);
            this.loading = false
          })
      } else {
        this.loading = true
        this.$apis
          .userFileBatchDelete({
            ids: (this.options.checkIdList || []).join(","),
          })
          .then((res: any) => {
            this.loading = false
            this.$message.success("删除成功");
            this.$emit("close", true);
          }).catch((err: any) => {
            console.log('err', err);
            this.loading = false
          })
      }
    } else {
      // TODO
      this.$emit("close", true);
    }
  }

  onClickCancle() {
    this.$emit("close");
  }

  @Watch("type", { immediate: true })
  typeChange(el: string) {
    //@ts-ignore
    if (el && setMap[el]) {
      fieldList.forEach((key: string) => {
        //@ts-ignore
        if (this[key] !== undefined) this[key] = setMap[el][key] || "";
      });
    }
  }

  created() { }
}


import Vue from 'vue'
import { Component, Prop, Watch, Ref } from 'vue-property-decorator'
import setMap from './json/setMap'
// 可选type列表
let typeList = Object.keys(setMap),
    // 配置字段
    fieldList = Object.keys(setMap.publicShare)
@Component({
    components: {},
    filters: {
        //局部过滤器
    },
})
export default class ClassComponent extends Vue {
    title: string = ''
    remark: string = ''
    link: string = 'http://www.csii.com.cn/sdjsjd45jsdjj99396tafasfasfagag'
    password: string = '123456'
    form: any = {
        periodValidity: 7,
        visitTimes: 0,
        downloadTimes: 0,
    }

    periodValidityList: any[] = [
        { text: '3天', value: 3 },
        { text: '7天', value: 7 },
        { text: '一个月', value: 30 },
    ]
    visitTimesList: any[] = [
        { text: '不限', value: 0 },
        { text: '3天', value: 3 },
        { text: '7天', value: 7 },
        { text: '一个月', value: 30 },
    ]
    downloadTimesList: any[] = [
        { text: '不限', value: 0 },
        { text: '10次', value: 10 },
        { text: '50次', value: 50 },
        { text: '100次', value: 100 },
    ]
    //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
    @Prop({
        type: String,
        default: 'publicShare',
        validator(value: string) {
            return typeList.includes(value)
        },
    })
    type!: string

    @Prop({
        type: Boolean,
        default: false,
    })
    visible!: boolean

    @Prop({
        type: Object,
        default: () => {},
    })
    options!: any

    onClickCancle() {
        this.$emit('close')
    }

    toCopy() {
        const text = document.createElement('textarea')
        text.value = this.link
        document.body.appendChild(text)
        text.select()
        document.execCommand('Copy')
        text.remove()
        this.$message.success('复制成功')
    }

    @Watch('type', { immediate: true })
    typeChange(el: string) {
        //@ts-ignore
        if (el && setMap[el]) {
            fieldList.forEach((key: string) => {
                //@ts-ignore
                if (this[key] !== undefined) this[key] = setMap[el][key] || ''
            })
        }
    }
}

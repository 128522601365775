//@ts-nocheck
// 这里是首页数据mixin混入, 单独拿出来tslint不知道this指向, 先禁用检测
import dayjs from "dayjs";
import { publicFileView, publicFormatSize } from "@/utils/index"; // 预览等公共函数
import { FileProps } from "@/interface";
import Loading from "@/components/Loading/index";
import menuData from "../json/menuData";
// let menuData=[]
import popupSetMap from "@/views/popup/json/setMap";
import { Spin } from "ant-design-vue";
import Vue from "vue";
import { mapGetters } from "vuex";

// 节流/唯一标识
import { throttle, creatClientId } from "@/common/utils/util";
import { imgEx, onlineEx } from "@/common/utils/fileEx";
let filesIcon = require("@/assets/images/files.png");
let popupKeys = Object.keys(popupSetMap);
let id = "id";
let checkedFileItemList: Array<string | number> = []; // 选择的文件本身
let checkIdList: Array<string | number> = [],
  tabList: { id: string | number; title: string }[] = [
    { id: 0, title: "全部文件", api: "userFilePageList" },
    // { id: 1, title: "最近更新----", api: "fileEditWeightPageList", functionCode: "VIEW_LAST_EDITED_FILE" },
    // { id: 1, title: "最近更新----", api: "fileEditWeightPageList", },
    { id: 1, title: "最近查看", api: "fileWeightPageList" },
    // { id: 2, title: "最近查看", api: "fileWeightPageList", functionCode: "VIEW_LAST_READED_FILE" },
    // { id: 3, title: "标签", api: "starPageList", functionCode: "VIEW_FILE_TAG" },
  ],
  apiName: string = "userFilePageList",
  previewImgList: { id: number | string; url: string }[] = [],
  previewId: string | number = "",
  breadcrumbList: { text: string; id: string | number }[] = [
    { text: "个人文档", id: "" },
  ],
  // sortIndicatorList: string[] = ["updateTime"],
  // 排序的默认选择项
  sortIndicatorList: string[] = ["createTime"],
  sortRuleList: string[] = ["asc"],
  conversionAction: string[] = ["pingpushitu"],
  popupType: string = "createFile",
  topCreateList: any[] = menuData.topCreate,
  topFilterList: any[] = menuData.topFilter,
  conversionModeList: any[] = menuData.conversionMode,
  listData: any[] = [],
  resList: any[] = [],
  popupOptions: any = {},
  popupLoading: boolean = false,
  checkAll: boolean = false,
  visible: boolean = false,
  showTable: string = "pingpushitu",
  tabIndex: number = 0,
  contextmenuValue: any = {},
  detailShow: boolean = false,
  isTeam: boolean = true,
  cooperationVisible: boolean = false,
  cooperationLoading: boolean = false,
  cooperationType: string = "public",
  shareVisible: boolean = false,
  shareLoading: boolean = false,
  shareType: string = "public",
  expireType: number = 0,
  shareScope: number = 0, // 分享范围
  shareName: string = "",
  shareFileVisible: boolean = false,
  shareFiles: any[] = [],
  shareKey: {
    shareName: string;
    shareUrl: string;
    accessPwd?: string;
  } = {},
  routeName: string = "VIEW_MY_DOCUMENT",
  moduleType: string = "doc",
  wrapLoading: boolean = false;
// mock数据
// import getAuthTree from "mock/DocSystem/Back/getAuthTree";
// let listTree = getAuthTree({ treeType: "groups" });

let mixin = {
  data() {
    return {
      fileWeightPageList: false,
      showSpin: true, // 控制 Spin 显示与隐藏的变量
      id, // 当前页面文件操作的id字段
      listData, // 文件列表
      resList, // 后台返回的文件列表
      previewImgList, // 预览图片列表
      breadcrumbList: [...breadcrumbList], //  面包屑

      topCreateList, // 右上角创建文件/文件夹menu
      topFilterList, // 右上角筛选条件menu
      conversionModeList, // 切换模式按钮

      sortIndicatorList, // 文件列表排序条件
      sortRuleList, // 文件列表排序方式
      conversionAction, // 选择的显示视图

      checkIdList, // 选中文件Id列表
      checkedFileItemList, // 选中文件列表
      previewId, // 正在预览的文件Id
      checkAll, // 标记, 文件是否全选

      tabList, // tab列表
      tabIndex, // tab选中下标
      showTable, // 展示表格|展开

      visible, // 标记, 是否展示弹窗
      popupType, // 弹窗类型
      popupLoading, // 弹窗加载
      popupOptions, //  弹窗options
      isTeam,
      apiName, // api接口名字

      contextmenuValue, // 右键点击的文件
      detailShow, // 文件详情显示
      cooperationVisible, // 协作显示
      cooperationLoading, // 协作loading
      cooperationType, // 协作类型
      shareVisible, // 分享弹出框显示
      shareLoading, // 分享弹出框loading
      expireType, // 分享过期时间
      shareScope, // 分享范围
      shareName, // 分享名称
      shareFileVisible, // 多个文件分享弹框
      shareFiles, // 分享的多个文件
      shareType, // 分享类型
      shareKey, // 选择分享文件id
      routeName, // 当前路由名称
      moduleType, // 模块类型
      wrapLoading, // 加载
      options: [
        // 个人文档类型列表
        {
          fileType: "doc",
          title: "文档",
          filter: [
            ".xls",
            ".xlsx",
            ".doc",
            ".docx",
            ".ppt",
            ".pptx",
            ".pdf",
            ".txt",
          ],
        },
        {
          fileType: "video",
          title: "音视频",
          filter: [
            ".mp3",
            ".wav",
            ".aac",
            ".m4a",
            ".flac",
            ".ogg",
            ".oga",
            ".opus",
            ".webm",
            ".mp4",
            ".mkv",
            ".avi",
            ".mov",
            ".wmv",
            ".rmvb",
          ],
        },
        {
          fileType: "image",
          title: "图片",
          filter: [".jpeg", ".jpg", ".png", ".gif", ".bmp", ".webp", ".tif"],
        },
        {
          fileType: "compress",
          title: "压缩包",
          filter: [
            ".zip",
            ".rar",
            ".tar.gz",
            ".tar.bz2",
            ".7z",
            ".xz",
            ".gzip",
          ],
        },
        {
          fileType: "other",
          title: "其他",
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["historyindex"]),
    // 文件详情显示
    showFileDetail(): any {
      // 选中多个文件默认展示最靠前文件
      // 获取选中文件最靠前的文件
      let index = this.checkIdList.reduce((total, value) => {
        let i = this.listData.findIndex((file) => file[this.id] === value);
        if (i < total || total === undefined) {
          return i;
        } else {
          return total;
        }
      }, undefined);
      return this.listData[index];
    },
    // 选择的单个文件
    checkFile(): any {
      return this.listData.find((i) => i[this.id] === this.checkIdList[0]);
    },
    token(): any {
      return (this.$store as any).state.user.token;
    },
    companyId(): any {
      return (this.$store as any).state.user.companyId;
    },
    HistoryIds() {
      return this.$store.state.cloud.HistoryIds;
    },
    isshowtoEnterBtn(): boolean {
      return this.HistoryIds.length - 1 === this.historyindex;
    },
  },

  provide() {
    return {
      vm: this,
    };
  },

  methods: {
    sendMessage(message: any) {
      this.$emit("message", message);
    },

    // 全选/清空
    onCheckAll() {
      if (this.checkAll) {
        this.checkIdList = [];
      } else {
        this.checkIdList = this.listData.map((el: any) => el[this.id]);
      }
    },

    // 选中/取消选中文件
    toCheckItem(el: FileProps, keyup: string, isMove: boolean = false) {
      // console.log(el[this.id], el.id)
      if (el[this.id]) {
        let arr: Array<string | number> = this.checkIdList;
        if (keyup === "ctrl") {
          if (arr.includes(el[this.id])) {
            // 如果不是文件移动取消选择
            if (!isMove) {
              arr.splice(
                arr.findIndex((item: any) => item === el[this.id]),
                1
              );
            }
          } else {
            // alert('888')
            arr.push(el[this.id]);
          }
        } else if (keyup === "shift") {
          if (this.checkIdList.length <= 0) {
            this.checkIdList = [el[this.id]];
          } else {
            let targetIndex = this.listData.findIndex(
              (i) => i[this.id] === el[this.id]
            );
            // 获取选中的文件中索引最靠前的
            let startIndex: number = arr.reduce((total, value) => {
              let index = this.listData.findIndex((i) => i[this.id] === value);
              if (total === undefined || total > index) {
                return index;
              } else {
                return total;
              }
            }, undefined);
            // 获取选中的文件中索引最靠后的
            let endIndex: number = arr.reduce((total, value) => {
              let index = this.listData.findIndex((i) => i[this.id] === value);
              if (total === undefined || total < index) {
                return index;
              } else {
                return total;
              }
            }, undefined);
            // console.log(startIndex, endIndex, targetIndex)
            // 索引范围
            let rangeIndex;
            // 当选中文件仅为1个时,根据点击的目标文件确定范围
            if (startIndex === endIndex) {
              rangeIndex = startIndex;
              this.getCheckIdList(targetIndex, rangeIndex);
            } else if (targetIndex - startIndex < endIndex - targetIndex) {
              rangeIndex = endIndex;
              this.getCheckIdList(targetIndex, rangeIndex);
            } else {
              rangeIndex = startIndex;
              this.getCheckIdList(targetIndex, rangeIndex);
            }
          }
        } else {
          // 如果不是文件移动取消选择
          if (arr.includes(el[this.id]) && arr.length === 1 && !isMove) {
            arr.splice(
              arr.findIndex((item: any) => item === el[this.id]),
              1
            );
          } else {
            this.checkIdList = [el[this.id]];
          }
        }
      }
    },

    // 按住shift选择文件
    getCheckIdList(targetIndex: number, rangeIndex: number) {
      if (targetIndex > rangeIndex) {
        this.checkIdList = this.listData
          .slice(rangeIndex, targetIndex + 1)
          .map((i) => i[this.id]);
      } else if (targetIndex < rangeIndex) {
        this.checkIdList = this.listData
          .slice(targetIndex, rangeIndex + 1)
          .map((i) => i[this.id]);
      }
    },

    // fileList组件在选中文件时触发, 返回了完整checkIdList, 直接赋值
    flSetCheckIdList(arr: any[]) {
      this.checkIdList = arr;
    },

    // 双击文件/文件夹
    toDblclickItem(el: FileProps) {
      let compressedFilesList = ["zip", "rar", "7z", "tar", "gz", "bz2", "xz"];
      // 如果是压缩文件 只支持zip解压
      if (compressedFilesList.includes(el.extensionName)) {
        if (el.extensionName == "zip") {
          // const spinInstance = new Spin({
          //   propsData: {
          //     tip: '解压中...', // 加载提示信息
          //   },
          // });
          // spinInstance.$mount();
          // const container = document.body;
          // container.appendChild(spinInstance.$el);
          // spinInstance.spin();
          const app = new Vue({
            render: (h) =>
              h(
                "div",
                {
                  // 添加id属性
                  attrs: {
                    id: "my-spin-Div",
                  },
                  style: {
                    zIndex: 9999,
                    position: "absolute",
                    top: "0",
                    left: "0",
                    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                  },
                },
                [
                  h(Spin, {
                    props: {
                      tip: "解压中...", // 加载提示信息
                    },
                  }),
                ]
              ),
          });
          const container = document.createElement("div");
          let spinBox = document.querySelector(".star-content");
          if (spinBox) {
            spinBox.appendChild(container);
            app.$mount(container);
          }

          // 调用解压接口
          this.$apis["userFileUnzip"]({
            userFileId: el[this.id],
          })
            .then((res) => {
              // 获取要移除的元素
              const elementToRemove = document.querySelector("#my-spin-Div");
              // 检查是否找到了元素
              if (elementToRemove) {
                // 从父元素中移除
                elementToRemove.parentNode.removeChild(elementToRemove);
              }
              app.$destroy();
              if (res.code == "200") {
                this.$message.success("解压成功");
                // 在个人文档界面
                if (this.routeName == "VIEW_MY_DOCUMENT") {
                  this.apiName = "userFilePageList";
                  this.fetchData("");
                } else {
                  let query = {
                    teamId: el.teamId,
                    _t: new Date() - 0,
                  };
                  this.$router.push({
                    name: "VIEW_COMPANYTEAM_DOCUMENT",
                    query,
                  });
                }
              }
            })
            .catch((err) => {
              console.log("解压失败", err);
              // 获取要移除的元素
              const elementToRemove = document.querySelector("#my-spin-Div");
              // 检查是否找到了元素
              if (elementToRemove) {
                // 从父元素中移除
                elementToRemove.parentNode.removeChild(elementToRemove);
              }
            });
        } else {
          // 弹窗提示不可解压
        }
      }
      // 如果是文件夹
      else if (parseInt(el.isDir) === 1) {
        let query = {
          id: el[this.id],
          teamId: el.teamId || this.$route.query.teamId,
        };
        // console.log('this.routeName是啥','VIEW_MY_DOCUMENT');
        this.$router.push({ name: this.routeName, query });
        // 点击文件夹添加id到vuex作为前进后退依据
        this.$store.commit("SET_addHistoryIds", query.id);
      }
      // 如果是markdown格式文件，需要支持编辑
      else if (el.extensionName == "md") {
        console.log("是markdown文件", el);
        this.$router.push({
          name: "MD_EDITOR",
          query: {
            type: "read",
            userFileId: el.id,
            parentId: el.parentId,
            teamId: el.teamId.length ? el.teamId : null,
          },
        });
      }
      // else if (onlineEx.includes(el.extensionName)) {
      else {
        console.log("要预览的文件", el);
        let tempEl = {
          ...el,
          actionFileid: el.id,
          actionFileSize: publicFormatSize(el.fileSize || el.userFileSize),
          actionFileFullName: `${el.userFileName}.${el.extensionName}`,
        };
        publicFileView(tempEl);
      }
      // 图片也改用统一预览服务
      // else if (imgEx.includes(el.extensionName)) {
      //   console.log("是图片？？？", el);

      //   // console.log(el.fileId, this.previewImgList);
      //   this.previewId = el.fileId;
      // }

      // else {
      //   this.downloadFile(el);
      // }
    },

    // 点击右上角小√取消选择
    handleUnselect(value: any) {
      this.checkIdList.splice(
        this.checkIdList.findIndex((item: any) => item === value[this.id]),
        1
      );
    },

    // 鼠标按下事件,用于文件拖拽
    handleMousedown(value: any, keyup: string, e: any) {
      // 获取移动的元素
      let isFile: HTMLDivElement | undefined = e
        .composedPath()
        .find((i) => i._prevClass && i._prevClass.includes("file-wrap"));
      if (isFile) {
        // 初始化位置
        let initX = e.pageX;
        let initY = e.pageY;
        // 元素所在位置信息
        let coordinate = isFile.getBoundingClientRect();
        // 获取边界线,边界线给与5像素的差值以防移动过快未同步
        let boundaryLeft = coordinate.left + 5;
        let boundaryTop = coordinate.top + 5;
        let boundaryRight = coordinate.left + coordinate.width - 5;
        let boundaryBottom = coordinate.top + coordinate.height - 5;
        let move = (e) => {
          // 如果移动超过30像素,或者即将移出当前元素时
          if (
            Math.abs(initX - e.pageX) > 30 ||
            Math.abs(initY - e.pageY) > 30 ||
            e.pageX >= boundaryRight ||
            e.pageX <= boundaryLeft ||
            e.pageY >= boundaryBottom ||
            e.pageY <= boundaryTop
          ) {
            // console.log("开始移动");
            // 克隆当前移动元素
            let cloneBox: HTMLDivElement = isFile?.cloneNode(true);
            // 清除原元素的移动
            document.onmousemove = null;
            // 添加选中
            if (value[this.id]) {
              this.toCheckItem(value, keyup, true);
            }
            // 判断是否移动多个文件
            if (this.checkIdList.length > 1) {
              let describe: HTMLSpanElement = document.createElement("span");
              describe.innerText = "等多个文件";
              describe.setAttribute("class", "font10 gray-font");
              cloneBox.appendChild(describe);
              cloneBox.getElementsByTagName("img")[0].src = filesIcon;
            }
            // 添加到页面中
            this.$refs.starContent.appendChild(cloneBox);
            // 右下角移动区域显示
            this.$store.commit("menu/SET_MOVE", true);
            this.$store.commit("menu/SET_WATCHCOORDINATE");
            // 绑定位置到鼠标处
            cloneBox.style.position = "fixed";
            cloneBox.style.maxWidth = "160px";
            cloneBox.style.zIndex = "999";
            cloneBox.style.left = e.pageX - 40 + "px";
            cloneBox.style.top = e.pageY - 50 + "px";
            // console.log(cloneBox)
            // 添加移动事件
            document.onmousemove = function (event) {
              cloneBox.style.left = event.pageX - 40 + "px";
              cloneBox.style.top = event.pageY - 50 + "px";
            };
            // 鼠标抬起清除改克隆元素
            cloneBox.onmouseup = (event) => {
              // 获取页面所有的文件元素(最后一项该拖拽元素,将其过滤)
              let fileWraps: HTMLDivElement[] = Array.from(
                this.$refs.starContent.getElementsByClassName("file-wrap")
              ).slice(0, -1);
              let index: number | undefined = undefined;
              // 获取目标坐标
              fileWraps.forEach((element, i) => {
                let coord = element.getBoundingClientRect();
                // console.log(i)
                // console.log(event.pageX,event.pageY)
                // console.log(coord.left,coord.left + coord.width,coord.top,coord.top + coord.height)
                if (
                  coord.left + coord.width >= event.pageX &&
                  event.pageX >= coord.left &&
                  coord.top + coord.height >= event.pageY &&
                  event.pageY >= coord.top
                ) {
                  index = i;
                }
              });
              if (!isNaN(index)) {
                // if (this.$store.state.user.functionCodes.includes('MOVE_FILE')) {
                // 无权限code码，暂时绕过
                if (1) {
                  let targetFile: any = this.listData[index];
                  // 是否是文件夹并且该文件夹不能是以选项
                  if (
                    targetFile.userFileType === "folder" &&
                    !this.checkIdList.includes(targetFile[this.id])
                  ) {
                    if (this.moduleType === "team") {
                      // 团队文件校验权限
                      this.$apis
                        .companyFileCheck({
                          sourceIds: this.checkIdList || [],
                          teamId: this.$route.query.teamId,
                        })
                        .then((res) => {
                          if (res.code === "200") {
                            // data为无权限文件名的数组
                            if (!res.data || res.data.length === 0) {
                              this.$apis
                                .companyFileMove({
                                  sourceIds: this.checkIdList,
                                  targetId: targetFile[this.id],
                                  teamId: this.$route.query.teamId,
                                })
                                .then((res: any) => {
                                  if (res.code == 200) {
                                    this.$message.success("移动成功");
                                    this.fetchData(
                                      this.$store.state.share.pathId
                                    );
                                  }
                                });
                            } else {
                              const that = this;
                              const h = this.$createElement;
                              this.$confirm({
                                title: "文件移动",
                                content: h("div", {}, [
                                  h(
                                    "p",
                                    "以下文件无操作权限,是否继续执行移动(无操作权限无法移动)"
                                  ),
                                  h(
                                    "p",
                                    `无操作权限文件：${res.data.join("、")}`
                                  ),
                                ]),
                                okText: "继续移动",
                                cancelText: "取消",
                                onOk() {
                                  that.$apis
                                    .companyFileMove({
                                      sourceIds: that.checkIdList,
                                      targetId: targetFile[that.id],
                                      teamId: that.$route.query.teamId,
                                    })
                                    .then((res: any) => {
                                      if (res.code == 200) {
                                        that.$message.success("移动成功");
                                        that.fetchData(
                                          that.$store.state.share.pathId
                                        );
                                      }
                                    });
                                },
                                onCancel() {
                                  console.log("Cancel");
                                },
                              });
                            }
                          }
                        });
                    } else {
                      this.$apis
                        .userFileMoves({
                          sourceIds: this.checkIdList,
                          targetId: targetFile[this.id],
                        })
                        .then((res: any) => {
                          if (res.code == 200) {
                            this.$message.success("移动成功");
                            this.fetchData(this.$store.state.share.pathId);
                          }
                        });
                    }
                  }
                } else {
                  this.$message.warning("文件移动功能未开启,请联系管理员");
                }
              } else {
                // 不是移动到文件下则判断是否移动到回收站
                let recycleCoord = this.$store.state.menu.moveCoordinate;
                let moveCoordinateTop = recycleCoord.top - 40;
                let moveCoordinateRight =
                  recycleCoord.left + recycleCoord.width + 30;
                let moveCoordinateBottom =
                  recycleCoord.top + recycleCoord.height + 40;
                let moveCoordinateLeft = recycleCoord.left - 30;
                if (
                  moveCoordinateRight >= event.pageX &&
                  event.pageX >= moveCoordinateLeft &&
                  moveCoordinateBottom >= event.pageY &&
                  event.pageY >= moveCoordinateTop
                ) {
                  // if (this.$store.state.user.functionCodes.includes('DELETE_FILE')) {
                  if (this.moduleType === "team") {
                    // 团队文件校验权限
                    this.$apis
                      .companyFileCheck({
                        sourceIds: this.checkIdList || [],
                        teamId: this.$route.query.teamId,
                      })
                      .then((res) => {
                        if (res.code === "200") {
                          // data为无权限文件名的数组
                          if (!res.data || res.data.length === 0) {
                            this.$apis
                              .companyFileDelete({
                                sourceIds: this.checkIdList || [],
                                teamId: this.$route.query.teamId,
                              })
                              .then((res: any) => {
                                this.$message.success("删除成功");
                                this.fetchData(this.$store.state.share.pathId);
                              });
                          } else {
                            const that = this;
                            const h = this.$createElement;
                            this.$confirm({
                              title: "文件删除",
                              content: h("div", {}, [
                                h(
                                  "p",
                                  "以下文件无操作权限,是否继续执行删除(无操作权限无法删除)"
                                ),
                                h(
                                  "p",
                                  `无操作权限文件：${res.data.join("、")}`
                                ),
                              ]),
                              okText: "继续删除",
                              cancelText: "取消",
                              onOk() {
                                that.$apis
                                  .companyFileDelete({
                                    sourceIds: that.checkIdList || [],
                                    teamId: that.$route.query.teamId,
                                  })
                                  .then((res: any) => {
                                    that.$message.success("删除成功");
                                    that.fetchData(
                                      that.$store.state.share.pathId
                                    );
                                  });
                              },
                              onCancel() {
                                console.log("Cancel");
                              },
                            });
                          }
                        }
                      });
                  } else {
                    this.$apis
                      .userFileBatchDelete({
                        ids: (this.checkIdList || []).join(","),
                      })
                      .then((res: any) => {
                        this.$message.success("删除成功");
                        this.fetchData(this.$store.state.share.pathId);
                      });
                  }
                  // } else {
                  //   this.$message.warning('删除功能未开启,请联系管理员')
                  // }
                }
              }
              document.onmousemove = null;
              this.$refs.starContent.removeChild(cloneBox);
              this.$store.commit("menu/SET_MOVE", false);
            };
          }
        };

        // 绑定移动事件
        document.onmousemove = move;

        // 若鼠标抬起移出移动事件
        isFile.onmouseup = function () {
          // console.log("移除移动");
          document.onmousemove = null;
        };
        // 父元素滚动直接取消文件移动,防止出现问题
        this.$refs.starContent.onscroll = () => {
          // console.log("滚动了");
          document.onmousemove = null;
          this.$refs.starContent.onscroll = null;
        };
      }
    },

    // 文件详情显示
    handleDetail() {
      this.detailShow = true;
    },

    // 点击menu触发事件
    handleClick(el: any, item: any = undefined) {
      if (
        this.$route.query.fileType &&
        (el.func === "add" || el.func === "uploader")
      ) {
        this.isTeam = false;
      } else {
        this.isTeam = true;
      }
      if (popupKeys.includes(el.func)) {
        // 如果是展示弹窗的事件
        let value = item || this.contextmenuValue;
        //  团队文件的重命名和修改备注需要校验更改权限
        if (
          this.moduleType === "team" &&
          (el.name === "rename" || el.name === "remark")
        ) {
          this.$apis
            .companyFileCheck({
              sourceIds: this.checkIdList || [],
              // teamId: el.teamId || this.$route.query.teamId,
              teamId:
                this.contextmenuValue.teamId ||
                el.teamId ||
                this.$route.query.teamId,
            })
            .then((res) => {
              if (res.code === "200") {
                this.visible = true;
                this.popupType = el.func;
                this.popupOptions = Object.assign(
                  {
                    item: value,
                    suffix: value.extensionName,
                    checkIdList: this.checkIdList,
                  },
                  el.options
                );
              }
            });
        } else {
          this.visible = true;
          this.popupType = el.func;
          this.popupOptions = Object.assign(
            {
              item: value,
              suffix: value.extensionName,
              checkIdList: this.checkIdList,
            },
            el.options
          );
        }
      } else if (el.func === "conversionMode") {
        console.log(el);
        // 如果是切换视图
        this.showTable = el._value;
        this.conversionModeList[0].text = el.text;
        // 切换视图时, 修改icon
        this.conversionModeList[0].iconfont = el.name;
        this.conversionAction = [el._value];
      } else if (el.func === "sort") {
        // 如果是排序事件
        // 维护排序规则数据, watch监听会自动给列表做排序
        if (el.sortFlag) {
          // 升序/倒序
          this.sortRuleList = [el.options.asc];
        } else {
          // 排序指标(文件名/创建时间等)
          this.sortIndicatorList = [el.options.indicator];
        }
        // 修改排序展示的文字及图标
        this.topFilterList.map((el: any) => {
          // 对应指标对象
          let indicitem: any = this.topFilterList[0].children.find(
              (el: any) => el._value === this.sortIndicatorList[0]
            ),
            // 升序/降序对象
            ruleItem: any = this.topFilterList[0].children.find(
              (el: any) => el._value === this.sortRuleList[0]
            );
          if (indicitem && ruleItem) {
            // 修改排序展示效果
            return Object.assign(el, {
              text: `按${indicitem.text}${ruleItem.text}`,
              iconfont: ruleItem._value === "asc" ? "shengxu" : "jiangxu",
            });
          } else {
            return el;
          }
        });
      } else if (el.func === "uploadFile") {
        // vuex打开弹窗
        this.$store.commit(
          "upload/SET_UPLOADCLICK",
          this.$store.state.share.pathId
        );
      } else if (el.func === "uploadFolder") {
        // vuex打开文件夹弹窗
        this.$store.commit(
          "upload/SET_UPLOADCLICKFOLDER",
          this.$store.state.share.pathId
        );
      } else if (el.func === "download") {
        let clientId: string = creatClientId();
        const wrap = document.createElement("div");
        wrap.setAttribute("style", "display: none");
        document.body.appendChild(wrap);
        this.checkIdList.forEach((value) => {
          const frame = document.createElement("iframe"); //创建a对象
          frame.setAttribute(
            "src",
            `/star-doc/file/download?clientId=${clientId}&userFileId=${value}&token=${this.token}&Tenant-Id=${this.companyId}`
          );
          frame.setAttribute("class", "download-iframe");
          wrap.appendChild(frame);
        });
        setTimeout(() => {
          document.body.removeChild(wrap);
        }, 60000);

        // 公开分享
      } else if (el.func === "publicShareModal") {
        this.shareType = "public";
        this.shareName = "";
        if (this.checkIdList.length > 1) {
          const checkFile: any[] = [];
          this.listData.forEach((file) => {
            if (this.checkIdList.includes(file[this.id])) checkFile.push(file);
          });
          this.shareFiles = checkFile;
          this.shareFileVisible = true;
        } else {
          this.shareFileOk();
        }

        // 私密分享
      } else if (el.func === "privacyShareModal") {
        this.shareType = "private";
        this.shareName = "";
        this.expireType = 0;
        this.shareScope = 0;
        if (this.checkIdList.length > 1) {
          const checkFile: any[] = [];
          this.listData.forEach((file) => {
            if (this.checkIdList.includes(file[this.id])) checkFile.push(file);
          });
          this.shareFiles = checkFile;
          this.shareFileVisible = true;
        } else {
          this.shareVisible = true;
        }
      } else if (el.func === "publicCooperation") {
        if (onlineEx.includes(this.checkFile.extensionName)) {
          this.cooperationType = "public";
          this.cooperationVisible = true;
        } else {
          this.$message.warning("此类文件无法协作,协作仅支持文档类型!");
        }
      } else if (el.func === "rangeCooperation") {
        if (onlineEx.includes(this.checkFile.extensionName)) {
          this.cooperationType = "range";
          this.cooperationVisible = true;
        } else {
          this.$message.warning("此类文件无法协作,协作仅支持文档类型!");
        }
        // 给文件添加标签
      } else if (el.func === "addTag") {
        console.log(el, this.checkIdList);
        if (this.checkIdList.length > 1) {
          this.$apis
            .documentTagAddFilesTag({
              moduleType: this.moduleType,
              companyId:
                this.moduleType === "doc"
                  ? "person"
                  : this.$store.state.user.companyId,
              objIds: this.checkIdList,
              tagId: el.value,
            })
            .then((res) => {
              if (res.code === "200") {
                this.$message.success("操作成功");
              }
            });
        } else {
          this.$apis
            .documentTagUpdateFileTag({
              moduleType: this.moduleType,
              companyId:
                this.moduleType === "doc"
                  ? "person"
                  : this.$store.state.user.companyId,
              objId: this.checkIdList[0],
              tagId: el.value,
            })
            .then((res) => {
              if (res.code === "200") {
                this.$message.success("操作成功");
                if (
                  this.$route.path.includes("tag") &&
                  this.$route.query.tagId === el.value
                ) {
                  this.fetchData(this.$store.state.share.pathId);
                  this.$refs.fileContextContent.style.display = "none"; // 隐藏menu
                } else {
                  this.$refs.fileMenu.queryCheckedTag(this.checkIdList);
                }
              }
            });
        }
      } else if (el.func === "favorite") {
        if (el.text === "收藏") {
          // 团队文件收藏
          if (this.moduleType === "team") {
            this.$apis
              .companyTeamFileCollectionAdd({
                moduleType: this.moduleType,
                fileIds: this.checkIdList,
                // userFileId: this.checkIdList[0],
                teamId: el.teamId || this.$route.query.teamId,
              })
              .then((res) => {
                if (res.code === "200") {
                  this.$message.success("收藏成功");
                  this.fetchData(this.$store.state.share.pathId);
                }
              });
          } else {
            this.$apis
              .fileCollectionAdd({
                // fileId: this.checkIdList[0],
                moduleType: this.moduleType,
                fileIds: this.checkIdList,
                type: "person",
              })
              .then((res) => {
                if (res.code === "200") {
                  this.$message.success("收藏成功");
                  this.fetchData(this.$store.state.share.pathId);
                }
              });
          }
        } else {
          // 团队文件取消收藏
          if (this.moduleType === "team") {
            console.log("团队文件收藏取消");
            let favoriteIds = this.listData
              .filter(
                (item) =>
                  this.checkIdList.includes(item.id) &&
                  item.favoriteId !== undefined
              )
              .map((item) => item.favoriteId);

            if (favoriteIds.length > 0) {
              this.checkIdList = favoriteIds;
            }
            this.$apis
              .companyTeamFileCollectionCancel({
                // fileIds: this.checkIdList[0],
                moduleType: this.moduleType,
                fileIds: this.checkIdList,
                teamId: el.teamId || this.$route.query.teamId,
              })
              .then((res) => {
                if (res.code === "200") {
                  this.$message.success("取消收藏成功");
                  this.fetchData(this.$store.state.share.pathId);
                }
              });
          } else {
            console.log("个人文件收藏取消");
            let favoriteIds = this.listData
              .filter(
                (item) =>
                  this.checkIdList.includes(item.id) &&
                  item.favoriteId !== undefined
              )
              .map((item) => item.favoriteId);

            if (favoriteIds.length > 0) {
              this.checkIdList = favoriteIds;
            }
            this.$apis
              .fileCollectionDelete({
                // fileId: this.checkIdList[0],
                fileIds: this.checkIdList,
                type: "person",
              })
              .then((res) => {
                if (res.code === "200") {
                  this.$message.success("取消收藏成功");
                  this.fetchData(this.$store.state.share.pathId);
                }
              });
          }
        }
      } else if (el.func === "sendTeamAction") {
        console.log(el, this.checkIdList);
        this.$apis
          .companyFileCopy({
            sourceIds: this.checkIdList,
            targetId: "",
            teamId: el.id,
          })
          .then((res: any) => {
            if (res.code == 200) {
              this.$message.success("发送成功");
            }
          });
      } else if (el.func === "sendDoc") {
        // console.log(el, this.checkIdList)
        this.$apis
          .userFileCopy({
            sourceIds: this.checkIdList,
            targetId: "",
          })
          .then((res: any) => {
            if (res.code == 200) {
              this.$message.success("发送成功");
            }
          });
      } else if (
        [
          "PDF_Word",
          "Word_PDF",
          "Excel_PDF",
          "PPT_PDF",
          "pdf_edit_addImage",
          "pdf_edit_brush",
          "pdf_edit_signature",
          "pdf_edit_watermark",
        ].includes(el.func)
      ) {
        // console.log(el, this.checkIdList)
        console.log("团队有发送这个文档工具函数吗--el.func", el.func);
        console.log("团队有发送这个文档工具函数吗--el", el);

        this.$emit(el.func, el);
      } else {
        // this.$message.info(`点击了${el.text}`);
        // TODO 其他目录点击事件在此拓展
      }
    },

    // 私密分享文件确认
    privateShareFileOk(shareName: string) {
      this.shareFileVisible = false;
      this.shareName = shareName;
      this.shareVisible = true;
    },

    // 分享过期时间
    handleExpire(value, item) {
      this.expireType = value;
      this.shareScope = item ? "REGISTERED" : "ALL";
      this.shareFileOk();
    },

    // 分享文件确认
    shareFileOk(shareName: string) {
      this.shareFileVisible = false;
      this.shareName = shareName ? shareName : this.shareName;
      let name: string = this.shareName
        ? this.shareName
        : this.checkFile.isDir === 0
        ? `${this.checkFile.userFileName}.${this.checkFile.extensionName}`
        : `${this.checkFile.userFileName}`;
      let params = {
        shareType: this.shareType,
        // shareUrl: location.origin + "/#/fileShare",
        userFileIds: this.checkIdList,
        expireType: this.shareType === "public" ? undefined : this.expireType,
        scope: this.shareType === "public" ? undefined : this.shareScope,
        shareName: name,
        companyId: "person",
      };
      this.shareVisible = true;
      this.shareLoading = true;
      this.$apis.shareCreateShare(params).then((res: any) => {
        // this.shareKey = { ...res.data, shareUrl: `${location.origin}/#/fileShare?shareKey=${res.data.shareUrl}`, shareName: name }
        // 嵌入到主应用里去了，这里要加入微应用的路由

        this.shareKey = {
          ...res.data,
          shareUrl: `${process.env.VUE_APP_FILE_VIVW}/star-pan-web/fileShare?shareKey=${res.data.shareUrl}`,
          shareName: name,
        };
        this.shareLoading = false;
      });
    },

    downloadFile(item: FileProps) {
      this.$message.info("开始下载文件");
      if (this.token) {
        const wrap = document.createElement("div");
        wrap.setAttribute("style", "display: none");
        document.body.appendChild(wrap);
        const frame = document.createElement("iframe"); //创建a对象
        frame.setAttribute(
          "src",
          `/star-doc/file/download?userFileId=${item[this.id]}&token=${
            this.token
          }&Tenant-Id=${this.companyId}`
        );
        frame.setAttribute("class", "download-iframe");
        wrap.appendChild(frame);
        setTimeout(() => {
          document.body.removeChild(wrap);
        }, 60000);
      }
    },

    // 页签切换只需要改api名称就行了, watch会重新调接口
    onTabChange(el: any) {
      // 切换个人文档标签清除当前路由保存的文件id
      setTimeout(() => {
        if (el.id === 0) this.$router.push({ name: this.routeName });
        this.apiName = el.api;
      }, 1);
    },

    SetPopupLoading(flag: boolean) {
      this.popupLoading = flag;
    },

    // 弹窗关闭事件, refresh是否需要获取新的列表数据
    onPopupClose(refresh: boolean = false, params: any) {
      this.visible = false;
      if (refresh) {
        setTimeout(() => {
          this.fetchData(this.$store.state.share.pathId).then(() => {
            // params一般为新建文件的文件id
            if (params) {
              let fileId = params;
              // 默认选中新增文件
              this.checkIdList = fileId ? [fileId] : [];
              // 企业团队新增发送动态
              // if (this.moduleType === 'team') {
              //   this.$apis.companyTeamDynamicAdd({
              //     operateType: "INSERT",
              //     operatedObjectId: fileId,
              //     teamId: this.$route.query.teamId
              //   })
              // }
            }
          });
        }, 500);
      }
    },

    // 右键菜单自定义
    onContextMenu(
      e: any,
      refName: string = "contextContent",
      el: any = {},
      type: string = "selection"
    ) {
      console.log("过滤类型右键菜单", el.userFileName);
      if (!el.userFileName) {
        this.$emit("clickOnTheBlankSpace", 0);
        console.log("点击了空白处--03");
      } else {
        this.$emit("clickOnTheBlankSpace", Date.now());
      }

      this.contextmenuValue = el;
      let id = el[this.id];
      // type区分表格是否多选 selection: 多选 / single: 单个
      // 如果传了el说明是在文件上点击的右键, 将此文件变成选中状态
      if (id && !this.checkIdList.includes(id) && type === "selection") {
        this.checkIdList.push(id);
      } else if (id && type === "single") {
        this.checkIdList = [id];
      }
      this.$refs.fileMenu.queryCheckedTag(this.checkIdList);

      this.toHideContext();
      let contextMenuDom: any = this.$refs[refName],
        containerDom: any = this.$refs.container;
      let toHideMenu = (e) => {
        // 点击tag选项不隐藏
        if (e.composedPath().find((i) => i._prevClass?.includes("menu-tag")))
          return;
        contextMenuDom.style.display = "none"; // 隐藏menu
        document.removeEventListener("click", toHideMenu, false); // 解除监听
      };
      let x = e.x - containerDom.offsetLeft,
        y = e.y - containerDom.offsetTop;
      contextMenuDom.style.display = "block"; // 先解除隐藏
      console.log(
        "🚀 ~ file: index.ts:1253 ~ handleMousedown ~ contextMenuDom:",
        contextMenuDom
      );

      // 解除隐藏之后才能获取到dom宽高
      let width = contextMenuDom.offsetWidth,
        height = contextMenuDom.offsetHeight;

      // 右/下方位置不够展示menu时, 向左/上方展示
      if (width + e.clientX >= document.body.clientWidth) {
        x -= width;
      }
      if (height + e.clientY >= document.body.clientHeight) {
        console.log("不够展示");

        y -= height;
      }
      contextMenuDom.style.top = y - 140 + "px";
      // contextMenuDom.style.left = x + 5 + "px";
      contextMenuDom.style.left = x - 325 + "px";

      document.addEventListener("click", toHideMenu); // 点击隐藏
    },

    onFileScroll() {
      return throttle(this.toHideContext);
    },

    // 隐藏所有右键菜单
    toHideContext() {
      let refArr = ["contextContent", "fileContextContent"];
      // 隐藏所有右键菜单
      refArr.forEach((ref: any) => {
        let contextDom = this.$refs[ref];
        contextDom ? (contextDom.style.display = "none") : null;
      });
    },

    // 列表赋值, 重新调接口获取了新数据就传res, 不传参则当做用现有数据重新排个序
    setData(res?: any) {
      if (res) {
        // 如果是重新调接口获取的数据
        this.resList = (res.list || []).map((el: any) => {
          if (el.userFileName === undefined) {
            // 如果接口没有返回userFileName字段, 视作fileName型字段, 此处做翻译
            return {
              ...el,
              userFileName: el.fileName,
              userFilePath: el.filePath,
            };
          } else {
            return el;
          }
        });
        // 清空已选文件
        this.checkIdList = [];
        // 清空图片预览列表
        this.previewImgList = [];
      }
      let list: any[] = (this.resList || []).sort(
        (a: FileProps, b: FileProps) => {
          if (b.isDir !== a.isDir) {
            return parseInt(b.isDir) - parseInt(a.isDir); // 排序, 文件夹在前
          } else if (this.sortRuleList[0] === "asc") {
            // 升序
            return this.sortWithIndicator(b, a);
          } else {
            // 降序
            return this.sortWithIndicator(a, b);
          }
        }
      );
      if (res) {
        list.forEach((el: FileProps) => {
          if (imgEx.includes(el.extensionName)) {
            this.previewImgList.push({
              id: el.fileId,
              url: this.getFileUrl(el),
            });
          }
        });
      }
      this.listData = list || [];
      console.log("this.previewImgList", this.previewImgList);
    },

    // 排序规则
    sortWithIndicator(a: any, b: any) {
      let indc = this.sortIndicatorList[0] || "";
      switch (indc) {
        case "userFileName":
          return a[indc].localeCompare(b[indc], "zh-CN"); // 拼音排序
        case "createTime":
        case "updateTime":
          return dayjs(a[indc]).valueOf() - dayjs(b[indc]).valueOf(); // 时间戳排序
        default:
          return parseInt(a[indc]) - parseInt(b[indc]); // 数字/数字型字符串排序
      }
    },

    // 点击面包屑跳转到指定文件夹
    jumpToFolder(el: any, i: number) {
      // 最后一项不让点击
      if (i < this.breadcrumbList.length - 1) {
        const query = {
          id: el[this.id],
          teamId: this.$route.query.teamId,
        };
        this.$store.commit("SET_addHistoryIds", query.id);
        this.$router.push({ name: this.routeName, query });
      }
    },

    toPreLevel() {
      if (this.breadcrumbList.length > 1) {
        let el = this.breadcrumbList[this.breadcrumbList.length - 2];
        const query = {
          id: el[this.id],
          teamId: this.$route.query.teamId,
        };
        this.$router.push({ name: this.routeName, query });
        this.$store.commit("SET_addHistoryIds", query.id);
      }
    },
    toPreEnter() {
      if (!this.isshowtoEnterBtn) {
        // 不是最后一级
        const query = {
          id: this.HistoryIds[this.historyindex + 1],
          teamId: this.$route.query.teamId,
        };
        this.$router.push({ name: this.routeName, query });
        this.$store.commit("SET_addHistoryIds", query.id);
      }
    },
    setStoreData(i: number) {
      // 维护store数据
      let current = this.breadcrumbList.map((item) => item.text).join("/");
      this.$store.commit("share/SET_CURRENT", current);
      this.$store.commit("share/SET_PATH_ID", this.breadcrumbList[i].id);
    },

    fetchData(id: string | number = "", apiName = "userFilePageList") {
      if (this.fileWeightPageList && apiName !== "fileWeightPageList") {
        return;
      }
      let query = {
        id,
      };
      console.log("---", this.$route.query.fileType);

      if (this.$route.query.fileType) {
        let fileType = this.$route.query.fileType;
        let options = [
          {
            fileType: "doc",
            title: "文档",
          },
          {
            fileType: "video",
            title: "音视频",
          },
          {
            fileType: "image",
            title: "图片",
          },
          {
            fileType: "compress",
            title: "压缩包",
          },
          {
            fileType: "other",
            title: "其他",
          },
        ];

        this.apiName = apiName;
        this.tabList = [
          {
            id: 0,
            title: options.find((opt) => opt.fileType === fileType)?.title,
            api: "userFilePageList",
          },
        ];
        query = {
          id,
          fileType: this.$route.query.fileType,
        };
      } else {
        this.tabList = [
          { id: 0, title: "全部文件", api: "userFilePageList" },
          { id: 2, title: "最近查看", api: "fileWeightPageList" },
        ];
      }
      this.wrapLoading = true;
      console.log(apiName);
      return this.$apis[apiName](query)
        .then((res: any) => {
          this.setData(res);
          return Promise.resolve();
        })
        .finally(() => {
          this.wrapLoading = false;
          this.fileWeightPageList = false;
        });
    },

    setBreadcrumb(id: string | number = "", breadcrumb?: any) {
      if (breadcrumb) {
        breadcrumbList = [breadcrumb];
      } else {
        breadcrumbList = [{ text: "个人文档", id: "" }];
      }
      if (id) {
        const pathDicApi =
          this.moduleType === "team"
            ? this.$apis.companyFileQueryPathDic
            : this.$apis.queryPathDic;
        const params = {
          id,
          teamId: this.$route.query.teamId,
        };
        return pathDicApi(params)
          .then((res: any[]) => {
            let resBreadcrumbList = res.data
              // 数据过滤清洗
              .filter((el: any) => el.dirName)
              .map((el: { pathFileId: string | number; dirName: string }) => {
                this.$store.commit("SET_addHistoryIds", el.pathFileId);
                return {
                  id: el.pathFileId,
                  text: el.dirName,
                };
              });
            this.breadcrumbList = breadcrumbList.concat(resBreadcrumbList);

            return Promise.resolve();
          })
          .catch(() => {
            // 接口错误, 一般为后台文件问题, 定位到根目录
            this.$router.push({
              name: this.routeName,
              query: {
                teamId: this.$route.query.teamId,
              },
            });
          })
          .finally(() => {
            // 维护store数据
            this.setStoreData(this.breadcrumbList.length - 1);
          });
      } else {
        this.breadcrumbList = [...breadcrumbList];
        this.setStoreData(0);
        return Promise.resolve();
      }
    },

    dataInit(flag = false) {
      let id = this.$route.query.id || "";
      this.fetchData(id).then(() => {
        // 如果有传fileId, 默认选中
        let fileId = this.$route.query.fileId;
        this.checkIdList = fileId ? [fileId] : [];
      });
      // 默认平铺视图
      this.handleClick({
        func: "conversionMode",
        name: "pingpushitu",
        text: "平铺视图",
        _value: "pingpushitu",
      });
      if (!flag) {
        // 获取面包屑
        this.setBreadcrumb(id);
      }
    },

    // 拼接文件完整地址, 用作图片预览
    getFileUrl(el: FileProps) {
      let token = this.$store.state.user.token;
      return `/star-doc/file/preview?token=${token}&userFileId=${
        el[this.id]
      }&channelType=web`;
    },

    // 预览结束, 清空标记
    previewHide() {
      this.previewId = null;
    },

    // 页面初始化
    ViewInit() {
      console.log("页面初始化");
    },
  },

  filters: {
    // 时间格式化
    timeFormat(time: string | number) {
      return dayjs(time).format("MM-DD HH:mm");
    },
  },

  watch: {
    checkIdList: {
      handler(val: any[]) {
        // 维护全选状态标记
        this.checkAll = val.length === this.listData.length;
        // 清空checkedFileItemList数组
        this.checkedFileItemList = [];
        // 遍历listData数组
        this.listData.forEach((item) => {
          // 检查当前项的id是否在checkIdList中
          if (val.includes(item.id)) {
            // 如果在，则添加到checkedFileItemList数组中
            this.checkedFileItemList.push(item);
          }
        });

        console.log("选择文件", this.checkedFileItemList);
      },
      deep: true,
    },

    // 页签切换会改api名字
    apiName: function (api: string) {
      // 清空面包屑
      this.breadcrumbList.splice(1);
      // 初始化store数据
      this.setStoreData(0);
      // 重新调接口
      this.fetchData("", api);
    },

    // 升序/降序
    sortRuleList: {
      handler() {
        // 排序加个假loading, 视觉有重排刷新效果
        Loading.open();
        Promise.resolve().then(() => {
          this.setData();
          Loading.close();
        });
      },
      deep: true,
    },

    // 排序指标(文件名/创建时间等)
    sortIndicatorList: {
      handler() {
        // 排序加个假loading, 视觉有重排刷新效果
        Loading.open();
        Promise.resolve().then(() => {
          this.setData();
          Loading.close();
        });
      },
      deep: true,
    },

    // 模块类型
    moduleType: {
      handler(val) {
        this.routeName =
          val === "team" ? "VIEW_COMPANYTEAM_DOCUMENT" : "VIEW_MY_DOCUMENT";
      },
    },
    breadcrumbList(v) {
      console.log(v);
      if (v.length === 1) {
        this.$store.commit("SET_clearHistoryIds");
      }
    },
    // query.id保存文件夹路径
    "$route.query": {
      handler(val) {
        console.log("路由参数val---", val);
        if (val.tabIndex) {
          return; // 最近查看不需要重加载
        }

        if (val.submitAiCreation) {
          this.apiName = "userFilePageList";
          this.fetchData("");
        }
        if (val.fileType && this.breadcrumbList.length === 1) {
          const text = this.options.find(
            (i) => i.fileType === this.$route.query.fileType
          ).title;
          // 为个人文档且只有根目录时 显示相应的根面包屑
          this.breadcrumbList.splice(0, 1, {
            id: "",
            text,
            textParent: text ? "个人文档" : "",
          });
          this.dataInit(true);
          return;
        }
        // 防止所在其他标签下点击个人文档标签不切换
        this.tabIndex = 0;
        this.dataInit();

        console.log("his.tabIndex ", this.tabIndex);
      },
      deep: true,
    },

    // 监听全局刷新标记
    "$store.state.common.reload": function () {
      // 刷新数据, 如果有弹窗, 关闭
      this.onPopupClose(true);
    },
  },

  created() {
    // 将文件格式缓存
    sessionStorage.fileType = JSON.stringify(
      this.options.map((i) => ({
        typeList: i.filter?.join(","),
        fileType: i.fileType,
      }))
    );
    this.ViewInit();
    if (Object.keys(this.$route.query).length && this.$route.query.tabIndex) {
      // this.fileWeightPageList  =true // 最近查看
      this.apiName = "fileWeightPageList";
      // 设置为列表视图最近文件
      this.handleClick({
        func: "conversionMode",
        name: "liebiaoshitu",
        text: "列表视图",
        _value: "liebiaoshitu",
      });
      return;
      // this.fetchData(v.id,'fileWeightPageList')
    }
    if (this.$route.query.fileType && this.breadcrumbList.length === 1) {
      const text = this.options.find(
        (i) => i.fileType === this.$route.query.fileType
      ).title;
      // 为个人文档且只有根目录时 显示相应的根面包屑
      this.breadcrumbList.splice(0, 1, {
        id: "",
        text,
        textParent: text ? "个人文档" : "",
      });
      this.dataInit(true);
      return;
    }

    this.dataInit();
  },
};

export default mixin;

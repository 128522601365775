import Vue from 'vue' // 引入Vue
import Loading from './index.vue' // 引入要动态创建的vue模板
import { ROUTER_BASE } from '@/common/utils/const'

const Indicator = Vue.extend(Loading) // vue子类
let instance: any


const ShadowDom: any = document.querySelector(`[data-name="${ROUTER_BASE}"]`)

const DOM: any = ShadowDom ? (ShadowDom.shadowRoot ? ShadowDom.shadowRoot.getElementById('loadingModals') : document.body) : document.body

let loadingCounter = 0

export default {
  open() {
    if (loadingCounter === 0) {
      if (!instance) {
        instance = new Indicator({
          el: document.createElement('div'),
        }) // 生成实例
      }
      // instance.$mount(document.createElement('div'))
      if (instance.visible) return
      // document.body.appendChild(instance.$el)
      DOM.appendChild(instance.$el)

      Vue.nextTick(() => {
        instance.visible = true
      })

    }
    loadingCounter++
  },

  close() {
    loadingCounter--
    if (loadingCounter <= 0) {
        loadingCounter = 0
      if (instance) {
        instance.visible = false
      }
    }
  },
}

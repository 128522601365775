
import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import SimpleModal from "./simpleModal.vue";
import ShareModal from "./shareModal.vue";
import Collaboration from "./Collaboration.vue";
import WarningModal from "./warningModal.vue";
import MoveModal from "./moveModal.vue";
import popupSetMap from "./json/setMap";
@Component({
  inheritAttrs: false,
  components: {
    SimpleModal,
    ShareModal,
    Collaboration,
    WarningModal,
    MoveModal,
  },
  filters: {
    //局部过滤器
  },
})
export default class ClassComponent extends Vue {
  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop(String)
  popupType!: string;

  @Prop({ type: Boolean, default: false })
  visible!: boolean;

  @Prop({ type: Boolean, default: false })
  loading!: boolean;

  onPopupClose(flag: boolean = false, params: any) {
    this.$emit("loading", false);
    this.$emit("close", flag, params);
  }

  onPopupLoading(flag: boolean = false) {
    this.$emit("loading", flag);
  }
@Watch('popupType', { deep: true })
change(val:any){
  console.log(val)
}
  get showSimple(): boolean {
    return (
      // popupType自维护, 必为popupSetMap中有的值
      // @ts-ignore
      popupSetMap[this.popupType].kindOfPopup === "simple" && this.visible
    );
  }

  get showShare(): boolean {
    return (
      // @ts-ignore
      popupSetMap[this.popupType].kindOfPopup === "share" && this.visible
    );
  }

  get showWarn(): boolean {
    return (
      // @ts-ignore
      popupSetMap[this.popupType].kindOfPopup === "warning" && this.visible
    );
  }

  get showCollaboration(): boolean {
    return (
      // @ts-ignore
      popupSetMap[this.popupType].kindOfPopup === "collaboration" &&
      this.visible
    );
  }

  get showMove(): boolean {
    return (
      ["move", "copy",'uploader','add'].includes(
        // @ts-ignore
        popupSetMap[this.popupType].kindOfPopup
      ) && this.visible
    );
  }
}

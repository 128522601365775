
// 纯展示用组件, 不处理任何逻辑
import Vue from 'vue'
import homeVue from './index.vue'
import { Component, Prop, Watch, Ref, Inject } from 'vue-property-decorator'
import MydocMenu from './menu.vue'
import { Empty } from "ant-design-vue";
import dayjs from 'dayjs'
import { FileProps } from '@/interface'
// 内容较多, 数据暂存混入文件, 此文件只写模板
import Mixins from "./mixin/index";
@Component({
    components: { MydocMenu, Empty },
    filters: {
        // 时间格式化
        timeFormat(time: string | number) {
            return dayjs(time).format('MM-DD HH:mm')
        },
    },
})
export default class ClassComponent extends Vue {
[x: string]: any;
UploaderList: any;
    Mixins() {
        return [Mixins]
    }
    data() {
        return {
            aaa: 222,
            UploaderList: [
                {
                    icon: "shangchuanwenjian",
                    title: "上传文件",
                    functionCode: "UPLOAD_LOCAL_FILE",
                    emit: (this as any).handleUpload,
                },
                {
                    icon: "shangchuanwenjianjia",
                    title: "上传文件夹",
                    functionCode: "UPLOAD_LOCAL_DIR",
                    emit: (this as any).handleUploadFolder,
                },
            ],
        } as any
    }

    // 文件列表
    @Prop({ type: Array, default: () => [] })
    listData!: any[]

    // 被选中文件id
    @Prop({ type: Array, default: () => [] })
    checkIdList!: any[]

    @Inject() readonly vm!: any;

    // 判断文件是否被选中
    isChecked(el: FileProps) {
        // @ts-ignore
        return el[this.vm.id] && this.checkIdList.includes(el[this.vm.id])
    }

    // 组件触发的所有事件直接外发, 父级决定TODO内容
    toCheckItem(el: FileProps, keyup?: string) {
        this.$emit('checkItem', el, keyup)
    }
    toDblclickItem(el: FileProps) {
        this.$emit('dbCheckItem', el)
    }
    toHandleMousedown(value: any, keyup: string, e: any) {
        this.$emit('sendMousedown', value, keyup, e)
    }
    handleClick(el: any, item: any = {}) {
        this.$emit('menuClick', el, item)
    }
    clickContextMenu(e: any, el: any) {
        this.$emit('contextMenu', e, el)
    }
    unselect(el: any) {
        this.$emit('sendUnselect', el)
    }
    newfolder() {
        (this.$parent?.$parent as any).handleAddFile('folder')
        //    that.handleAddFile('folder')
    }
    upload() {
        (this.$parent?.$parent as any).handleAddFile('folder')
    }
    handleUploadFolder() {
        (this.$parent?.$parent as any).handleUploadFolder()
    }
    handleUpload() {
        (this.$parent?.$parent as any).handleUpload()
    }
} 


import Vue from "vue";
import { Component, Prop, Watch, Ref } from "vue-property-decorator";
import { namespace } from "vuex-class";

const MenuModule = namespace("menu");
const UserModule = namespace("user");

@Component({
  name: "FileTag",
  components: {},
  filters: {
    //局部过滤器
  },
})
export default class FileTag extends Vue {
  // 仓库数据
  @UserModule.State("companyId") companyId!: string;
  @MenuModule.State("tagList") fileTagList!: Array<any>;
  @MenuModule.Action("GET_TAGLIST") getTagList!: () => void;

  // 添加标签选择框显示
  public visible: boolean = false;
  // 全部标签内容
  public tagListAll: any[] = [];
  // 添加标签输入框显示
  public addTagShow: boolean = false;
  // 添加标签的内容
  public addTagValue: string = "";

  //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
  @Prop({ default: () => [] })
  readonly tagList!: any[];

  @Prop({
    type: String,
    default: "doc",
  })
  moduleType!: string;
  //计算属性
  get computedTagList(): Array<any> {
    return this.fileTagList;
  }
  //数据监听 Watch('监听数据', { deep: Boolean | 深度监听, immediate: Boolean | 初始化调用 })
  @Watch("computedTagList", { deep: true, immediate: true })
  fileTagListChange(newVal: any) {
    console.log(newVal);
    this.tagListAll = JSON.parse(JSON.stringify(newVal));
    this.setTagListChecked();
  }
  //ref 引用元素 Ref('ref')
  //@Ref()
  //创建前钩子函数

  // 设置选中标签
  setTagListChecked() {
    this.tagListAll.forEach((i: any) => {
      if (this.tagList.find((tag) => tag.id === i.id)) {
        this.$set(i, "checked", true);
      } else {
        this.$set(i, "checked", false);
      }
    });
  }
  // 点击事件
  handleMenuClick(e: any): void {
    if (e.key === "addTag") {
      this.addTagValue = "";
      this.addTagShow = true;
    } else if (e.key !== "tagInput") {
      this.visible = false;
      console.log(e);
    }
  }
  // 新增标签确认
  addTagOk(): void {
    if (this.addTagValue === "") {
      this.addTagShow = false;
      return;
    } else {
      this.$apis
        .documentTagAddTag({
          tagName: this.addTagValue,
          companyId: this.companyId,
        })
        .then((res: any) => {
          if (res.code === "200") {
            // this.$message.success("新增标签成功");
            this.$store.dispatch("menu/GET_TAGLIST");
            this.$emit("sendTag", res.data);
          }
        })
        .finally(() => {
          this.addTagShow = false;
        });
    }
  }
  // 添加/删除标签
  handleTag(id: string): void {
    this.$emit("sendTag", id);
  }

  created(): void {}
}

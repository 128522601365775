
import Vue from 'vue'
import { Component, Prop, Watch, Ref } from 'vue-property-decorator'
import setMap from './json/setMap'
// 可选type列表
let typeList = Object.keys(setMap),
    // 配置字段
    fieldList = Object.keys(setMap.publicCollaboration)
@Component({
    components: {},
    filters: {
        //局部过滤器
    },
})
export default class ClassComponent extends Vue {
    title: string = ''
    remark: string = ''
    searchPageShow: boolean = false
    searchText: string = ''
    searchResult: any = {
        username: 'Tina',
        account: '13088088800',
        purviewSelectShow: false,
        purviewText: '可查看',
    }
    inviteUrl: string = 'http://www.csii.com.cn/sdjsjd456tsdjsjd456tsdjsjd456t'
    purviewText: string = '可查看'
    purviewList: any[] = [
        { text: '可查看', value: 0 },
        { text: '可编辑', value: 1 },
        // {text: '移除成员', value: 2}
    ]
    purviewListPrivate: any[] = [
        { text: '可查看', value: 0 },
        { text: '可编辑', value: 1 },
        { text: '移除成员', value: 2 },
    ]
    userList: any[] = [
        {
            username: 'Tina',
            account: '13088088800',
            purviewSelectShow: false,
            purviewText: '可查看',
        },
        {
            username: '张吉成',
            account: '13088088800',
            purviewSelectShow: false,
            purviewText: '可编辑',
        },
        {
            username: 'Gina',
            account: '13088088800',
            purviewSelectShow: false,
            purviewText: '可编辑',
        },
        {
            username: '张A成',
            account: '13088088800',
            purviewSelectShow: false,
            purviewText: '可查看',
        },
        {
            username: 'Sina',
            account: '13088088800',
            purviewSelectShow: false,
            purviewText: '可查看',
        },
        {
            username: '张B成',
            account: '13088088800',
            purviewSelectShow: false,
            purviewText: '可查看',
        },
    ]
    //props定义 需要依赖装饰器 @Prop({ default: '默认值', type: '数据类型' })
    @Prop({
        type: String,
        default: 'publicCollaboration',
        validator(value: string) {
            return typeList.includes(value)
        },
    })
    type!: string

    @Prop({
        type: Boolean,
        default: false,
    })
    visible!: boolean

    @Prop({
        type: Object,
        default: () => {},
    })
    options!: any

    onClickCancle() {
        this.$emit('close')
    }

    purviewChange(index: number) {
        console.log(index)
        this.purviewText = this.purviewList[index].text
    }

    showSearchPage(v: boolean) {
        this.searchPageShow = v
    }

    purviewChangePrivate(item: any, index: number, Selitem: any) {
        const that = this
        console.log(item, index, Selitem)
        if (Selitem.value != 2) {
            that.userList[index].purviewText = Selitem.text
        } else {
            this.$confirm({
                title: '确定移除' + item.username + '吗？',
                centered: true,
                // content: h => <div><div>
                onOk() {
                    that.userList.splice(index, 1)
                },
                onCancel() {
                    console.log('Cancel')
                },
            })
        }
    }

    sliceStr(str: string) {
        let len = 0
        for (let i = 0; i < 4; i++) {
            if (str.charCodeAt(i) > 128) {
                len += 2
            } else {
                len += 1
            }
        }
        // console.log(len)
        if (len > 4) {
            return str.slice(0, 2)
        } else {
            return str.slice(0, 4)
        }
    }

    search() {
        console.log(this.searchText)
    }

    onCopy(e?: any) {
        // console.log('onCopy',e)
        this.$message.success('复制成功')
    }

    onError(e?: any) {
        // console.log('onError',e)
        this.$message.error('复制失败')
    }

    @Watch('type', { immediate: true })
    typeChange(el: string) {
        //@ts-ignore
        if (el && setMap[el]) {
            fieldList.forEach((key: string) => {
                //@ts-ignore
                if (this[key] !== undefined) this[key] = setMap[el][key] || ''
            })
        }
    }
}
